import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tag,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import WarehouseProvider from 'components/providers/WarehouseProvider';
import { WarehouseTransactionContext } from 'components/providers/WarehouseTransactionProvider';
import { TransactionI, TransactionStatus, TransactionType } from 'models/transaction';
import { FC, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSave } from 'react-icons/fa';
import { FaBoxesPacking, FaTruckArrowRight, FaTruckRampBox } from 'react-icons/fa6';
import { MdExitToApp } from 'react-icons/md';
import { TbTruckReturn } from 'react-icons/tb';
import { StatusTagColor, TransactionTypeColor } from 'utils/constants/color';
import { NextValidStatus } from 'utils/constants/transaction';
import { getTransactionDetail } from 'utils/services/apis/transaction.api';
import TransactionForm from './TransactionForm';
import TransactionStep from './TransactionStep';

type TransactionModalModalProps = {
  type: TransactionType;
  transactionId?: string;
  open: boolean;
  onClose: () => void;
};

const UpdateButtonProps: Record<TransactionStatus, ButtonProps> = {
  [TransactionStatus.NOT_PROCESS]: {},
  [TransactionStatus.PREPARE]: {
    bgColor: StatusTagColor[TransactionStatus.PREPARE],
    _hover: { bgColor: 'yellow.600' },
    leftIcon: <FaBoxesPacking />,
    color: 'black',
  },
  [TransactionStatus.EXPORTED]: {
    bgColor: StatusTagColor[TransactionStatus.EXPORTED],
    _hover: { bgColor: 'blue.700' },
    leftIcon: <FaTruckArrowRight />,
    color: 'white',
  },
  [TransactionStatus.IMPORTED]: {
    bgColor: StatusTagColor[TransactionStatus.EXPORTED],
    _hover: { bgColor: 'teal.700' },
    leftIcon: <FaTruckRampBox />,
    color: 'white',
  },
  [TransactionStatus.RETURN]: {
    bgColor: StatusTagColor[TransactionStatus.RETURN],
    _hover: { bgColor: 'purple.300' },
    leftIcon: <TbTruckReturn />,
    color: 'white',
  },
  [TransactionStatus.CANCEL]: {
    bgColor: StatusTagColor[TransactionStatus.CANCEL],
    _hover: { bgColor: 'red.700' },
    leftIcon: <CloseIcon />,
    color: 'white',
  },
  [TransactionStatus.DONE]: {
    bgColor: StatusTagColor[TransactionStatus.DONE],
    _hover: { bgColor: 'green.700' },
    leftIcon: <CheckIcon />,
    color: 'white',
  },
};

const TransactionModal: FC<TransactionModalModalProps> = ({ type, transactionId, open, onClose }) => {
  const { t } = useTranslation();
  const { warehouse, isLoading } = useContext(WarehouseTransactionContext);
  const formRef = useRef<{ submit: (nextStatus?: TransactionStatus) => void }>(null);

  const {
    data: transaction,
    isFetching,
    refetch,
  } = useQuery<TransactionI>({
    queryKey: ['transaction', warehouse?.id, transactionId],
    queryFn: ({ queryKey }) => getTransactionDetail(queryKey[1] as string, queryKey[2] as string),
    enabled: !!transactionId && !!warehouse?.id,
  });

  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose} scrollBehavior="outside" isCentered>
      <ModalOverlay />
      <ModalContent minW="80vw" minH="80vh">
        <ModalHeader>
          <Flex alignItems="center" gap={3}>
            {!!transaction?.status && (
              <Tag bgColor={StatusTagColor[transaction.status]} color="white" paddingX="1rem">
                {t(`warehouse.transaction.status.${transaction.status.toLowerCase()}`)}
              </Tag>
            )}
            {transaction?.name ? transaction.name + ' - ' : ''}
            {!!(type || transaction?.type) && (
              <Text color={TransactionTypeColor[type || transaction?.type]}>
                {t(`warehouse.transaction.menu.${(type || transaction?.type)?.toLowerCase()}`)}
              </Text>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <WarehouseProvider>
            {(type || transaction?.type) && (
              <Skeleton minH="30vh" isLoaded={!isFetching && !isLoading}>
                {[TransactionType.IMPORT, TransactionType.EXPORT].includes(type ?? transaction?.type) && (
                  <TransactionStep status={transaction?.status} />
                )}
                <TransactionForm
                  transaction={transaction}
                  creatingType={type}
                  onRefetchTransaction={async () => {
                    if (transactionId) await refetch();
                    else onClose();
                  }}
                  ref={formRef}
                />
              </Skeleton>
            )}
          </WarehouseProvider>
        </ModalBody>
        <ModalFooter gap={4} display="flex" justifyContent="space-between">
          <Flex gap={3}>
            {!transaction ? (
              <Button
                bgColor="blue.500"
                _hover={{ bgColor: 'blue.700' }}
                color="white"
                minW="7rem"
                leftIcon={<FaSave />}
                onClick={() => formRef.current?.submit()}
              >
                {t('buttons.add')}
              </Button>
            ) : (
              NextValidStatus[transaction.status].type === transaction.type && (
                <>
                  {NextValidStatus[transaction.status].statuses.map((status) => (
                    <Button
                      key={status}
                      {...UpdateButtonProps[status]}
                      minW="7rem"
                      onClick={() => formRef.current?.submit(status)}
                    >
                      {status === transaction.status
                        ? t('buttons.update')
                        : `${t('buttons.change')} ${t(`warehouse.transaction.status.${status.toLowerCase()}`)}`}
                    </Button>
                  ))}
                </>
              )
            )}
          </Flex>

          <Button
            bgColor="gray.600"
            _hover={{ bgColor: 'gray.200' }}
            color="white"
            minW="200px"
            rightIcon={<MdExitToApp />}
            onClick={onClose}
          >
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TransactionModal;
