import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Pagination from 'components/pagination';
import { ProductContext } from 'components/providers/ProductProvider';
import { ProductI } from 'models/product';
import { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/helper.ts/common';
import ProductFilter from './ProductFilter';

const ProductTable: FC<{ onClickEdit: (id: string) => void }> = ({ onClickEdit }) => {
  const { t } = useTranslation();
  const { data } = useContext(ProductContext);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = useMemo<ColumnDef<ProductI>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            borderColor="gray.700"
            isChecked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            isIndeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        id: 'images',
        accessorKey: 'images',
        header: () => '',
        cell: ({ getValue }) => <Image src={getValue<string[]>()[0]} width={70} height={70} />,
      },
      {
        id: 'code',
        accessorKey: 'code',
        header: t('products.fields.code'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: t('products.fields.name'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        accessorFn: (row) => `${formatCurrency(row.sellingPrice)} ${row.currency}`,
        id: 'sellingPrice',
        header: t('products.fields.sellingPrice'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        id: 'supplier',
        accessorFn: ({ supplier }) => supplier?.name ?? t('common.na'),
        header: t('products.fields.supplier'),
        cell: ({ getValue }) => getValue<String>(),
      },
      {
        id: 'productGroup',
        accessorFn: ({ productGroup }) => productGroup?.name ?? t('common.na'),
        header: t('products.fields.group'),
        cell: ({ getValue }) => getValue<string>(),
      },
    ],
    [t]
  );

  const productTable = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    getRowId: (e) => e.id,
    enableRowSelection: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  });

  return (
    <Card flexDirection="column" w="100%" px="0px" minH="60vh">
      <Flex px="25px" my="8px" justifyContent="space-between" align="center">
        <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
          {t('products.title')}
        </Text>
        <ProductFilter type="product" />
      </Flex>

      <TableContainer>
        <Table size="sm" mb="24px" mt="30px">
          <Thead bgColor="pink.400" color="white">
            {productTable.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    color="inherit"
                    py={5}
                    key={header.id}
                    colSpan={header.colSpan}
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    fontSize="0.875rem"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {productTable.getRowModel().rows.map((row, index) => (
              <Tr
                key={row.id}
                bgColor={index % 2 ? 'white' : 'pink.50'}
                _hover={{ bgColor: 'pink.500', color: 'white', cursor: 'pointer' }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize="0.875rem"
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor="transparent"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={8}>
                <Pagination
                  currentPage={productTable.getState().pagination.pageIndex + 1}
                  totalPages={productTable.getPageCount()}
                  totalRows={data.length}
                  onPageIndexChange={productTable.setPageIndex}
                  pageSize={productTable.getState().pagination.pageSize}
                  setPageSize={productTable.setPageSize}
                  canNextPage={productTable.getCanNextPage()}
                  canPreviousPage={productTable.getCanPreviousPage()}
                />
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProductTable;
