import { Flex, Link, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { WarehouseInventoryContext } from 'components/providers/WarehouseInventoryProvider';
import dayjs from 'dayjs';
import { InventoryHistoryItem, TransactionType } from 'models/transaction';
import { WarehouseProductI } from 'models/warehouse';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TbTruckDelivery } from 'react-icons/tb';
import { Link as ReactRouterLink } from 'react-router-dom';
import { TransactionTypeColor } from 'utils/constants/color';
import { getInventoryHistories } from 'utils/services/apis/inventory.api';

const InventoryHistory: FC<{ product: WarehouseProductI }> = ({ product }) => {
  const { t } = useTranslation();
  const { warehouseId } = useContext(WarehouseInventoryContext);
  const { data: histories = [] } = useQuery({
    queryKey: [warehouseId, product?.id, 'history'],
    queryFn: async ({ queryKey }) => await getInventoryHistories(queryKey[0], queryKey[1]),
    enabled: !!product && !!warehouseId,
  });

  const getChangeSign = (type: TransactionType, quantity: number) => {
    let isAdd = true;
    if (![TransactionType.IMPORT, TransactionType.IMPORT_PRIMARY_WAREHOUSE].includes(type)) isAdd = !isAdd;
    if (quantity < 0) isAdd = !isAdd;
    return isAdd ? '+' : '-';
  };

  const getNote = (item: InventoryHistoryItem) => {
    if (item.type === TransactionType.EXPORT)
      return t('warehouse.transaction.exportTo', { warehouse: item.importWarehouse });
    if (item.type === TransactionType.IMPORT)
      return t('warehouse.transaction.importFrom', { warehouse: item.exportWarehouse });
    return '';
  };

  return (
    <TableContainer>
      <Table size="sm" variant="striped">
        <TableCaption fontSize="18px" bgColor="cyan.50" m={0}>
          <Flex minH="20vh" justifyContent="center" alignItems="center" gap={2}>
            <TbTruckDelivery fontSize={30} />
            {!histories.length && 'Không có lịch sử hàng hóa'}
          </Flex>
        </TableCaption>
        <Thead bgColor="blue.50">
          <Tr>
            <Th pr={0}></Th>
            <Th pl={0}>SL</Th>
            {!product.noExpiry && <Th>HSD</Th>}
            <Th>Loại giao dịch</Th>
            <Th>Mã vận đơn</Th>
            <Th>Ghi chú</Th>
            <Th textAlign="end">Ngày Cập nhật</Th>
          </Tr>
        </Thead>
        <Tbody>
          {histories.map((item) => (
            <Tr key={`${item.transactionId}-${item.quantitationUnit}=${item.expiry || 'noExpiry'}`}>
              <Td pr={0}>{getChangeSign(item.type, item.quantity)}</Td>
              <Td pl={0}>
                {Math.abs(item.quantity)} {item.quantitationUnit}
              </Td>
              {!product.noExpiry && <Td>{dayjs(item.expiry).format('DD/MM/YYYY') || ''}</Td>}
              <Td color={TransactionTypeColor[item.type]} fontWeight={600}>
                {t(`warehouse.transaction.type.${item.type.toLocaleLowerCase()}`)}
              </Td>
              <Td>
                <Link as={ReactRouterLink} color="blue.300" fontWeight={600} to="/products">
                  #{item.transactionName}
                </Link>
              </Td>
              <Td>{getNote(item)}</Td>
              <Td textAlign="end">{dayjs(item.modifiedAt).format('DD/MM/YYYY HH:mm')}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default InventoryHistory;
