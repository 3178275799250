import axios from 'axios';
import { CreateProductRequestI, ProductFilterQuery, ProductI, UpdateProductRequestI } from 'models/product';
import { ENDPOINTS } from 'utils/constants/endpoint';

const PRODUCT_ENDPOINT = `${process.env.REACT_APP_ENDPOINT}${ENDPOINTS.products}`;
export const fetchProducts = async (filter: ProductFilterQuery) =>
  await axios
    .get<ProductI[]>(PRODUCT_ENDPOINT, {
      params: {
        ...filter,
        productGroupIds: filter.productGroupIds.join(","),
        supplierIds: filter.supplierIds.join(","),
        prices: filter.prices.join(","),
      },
    })
    .then((resp) => resp.data);

export const getDetail = async (id: string) =>
  await axios.get<ProductI>(`${PRODUCT_ENDPOINT}/${id}`).then((resp) => resp.data);

export const createProduct = async (product: CreateProductRequestI) =>
  await axios.post<ProductI>(PRODUCT_ENDPOINT, product).then((resp) => resp.data);

export const updateProduct = async (id: string, product: UpdateProductRequestI) =>
  await axios.patch<ProductI>(`${PRODUCT_ENDPOINT}/${id}`, product).then((resp) => resp.data);

export const deleteProduct = async (id: string) =>
  await axios.delete<ProductI>(`${PRODUCT_ENDPOINT}/${id}`).then((resp) => resp.data);
