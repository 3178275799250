import { DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
} from '@chakra-ui/react';
import { ProductProperty } from 'models/product';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Properties: FC<{ values?: ProductProperty[]; onChange: (values: ProductProperty[]) => void }> = ({
  values = [],
  onChange,
}) => {
  const { t } = useTranslation();

  const clickAddHandler = () => {
    onChange([...values, { value: '', property: '' }]);
  };

  const changePropertyHandler = (index: number, field: keyof ProductProperty, value: string) => {
    values[index][field] = value;
    onChange([...values]);
  };

  const deletePropertyHandler = (index: number) => {
    onChange(values.filter((_v, i) => i !== index));
  };

  const checkDuplicate = (index: number, property: string) =>
    values?.some((v, i) => i !== index && v.property.trim() === property.trim());

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem border="1px" borderColor="gray.200">
        <h2>
          <AccordionButton bgColor="gainsboro">
            <Box as="span" flex="1" textAlign="left">
              {t('products.fields.properties')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Flex direction="column" alignItems="flex-start" gap={3}>
            {values?.map((prop, index) => (
              <Flex gap={4} key={`prop-${index}`}>
                <FormControl isInvalid={checkDuplicate(index, prop.property) || !prop.property}>
                  <Input
                    placeholder={t('products.fields.propertyName')}
                    value={prop.property}
                    onChange={(e) => changePropertyHandler(index, 'property', e.target.value)}
                  />
                  <FormErrorMessage color="red">
                    {!prop.property &&
                      t('messages.validations.fieldRequired', { field: t('products.fields.propertyName') })}
                    {checkDuplicate(index, prop.property) &&
                      t('messages.validations.unique', { field: t('products.fields.propertyName') })}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!prop.value}>
                  <Input
                    placeholder={t('products.fields.propertyValue')}
                    value={prop.value}
                    onChange={(e) => changePropertyHandler(index, 'value', e.target.value)}
                  />
                  <FormErrorMessage color="red">
                    {!prop.value &&
                      t('messages.validations.fieldRequired', { field: t('products.fields.propertyValue') })}
                  </FormErrorMessage>
                </FormControl>
                <IconButton
                  aria-label="delete-property"
                  bgColor="red"
                  color="white"
                  icon={<DeleteIcon />}
                  onClick={() => deletePropertyHandler(index)}
                />
              </Flex>
            ))}
            <Button
              colorScheme="green"
              size="md"
              width="200px"
              onClick={clickAddHandler}
              rounded="none"
              leftIcon={<PlusSquareIcon />}
            >
              {t('buttons.add')} {t('products.fields.properties').toLowerCase()}
            </Button>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Properties;
