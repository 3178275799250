import { useMutation, useQuery } from '@tanstack/react-query';
import { CrudContextType } from 'models/context-type';
import { CreateSupplierRequestI, SupplierI } from 'models/supplier';
import { FC, ReactNode, createContext, useContext, useEffect } from 'react';
import { createSupplier, fetchSuppliers } from 'utils/services/apis/supplier.api';
import { GlobalAppContext } from './AppProvider';

type SupplierContextType = CrudContextType<SupplierI, CreateSupplierRequestI>;
export const SupplierContext = createContext<SupplierContextType>({} as SupplierContextType);

const SupplierProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const {
    data = [],
    refetch: fetchGroupsHandler,
    isFetching,
  } = useQuery<SupplierI[]>({
    queryKey: ['suppliers'],
    queryFn: () => fetchSuppliers({}),
  });

  const { mutateAsync: createSupplierHandler, isPending: isCreateLoading } = useMutation({
    mutationFn: async (group: CreateSupplierRequestI) => await createSupplier(group),
    onSuccess: async () => await fetchGroupsHandler(),
  });

  useEffect(() => {
    toggleLoading(isCreateLoading || isFetching, 'supplier');
  }, [isCreateLoading, isFetching]);

  return (
    <SupplierContext.Provider
      value={{
        data,
        create: createSupplierHandler,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export default SupplierProvider;
