import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Stack,
  Tag,
  TagCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ProductGroupContext } from 'components/providers/ProductGroupProvider';
import { ProductContext } from 'components/providers/ProductProvider';
import { SupplierContext } from 'components/providers/SupplierProvider';
import { WarehouseInventoryContext } from 'components/providers/WarehouseInventoryProvider';
import { ProductFilterQuery } from 'models/product';
import { FC, useContext, useState } from 'react';
import { FaFilter } from 'react-icons/fa6';
import Select from 'react-select';
import { DEFAULT_PRODUCT_FILTER } from 'utils/constants/default-value';
import { MAX_INPUT_PRICE } from 'utils/constants/transaction';
import { formatCurrency } from 'utils/helper.ts/common';

const ProductFilter: FC<{ type: 'product' | 'inventory' }> = ({ type }) => {
  const { setFilter: setProductFilter } = useContext(ProductContext);
  const { setFilter: setInventoryFilter } = useContext(WarehouseInventoryContext);
  const [draftFilter, setDraftFilter] = useState<ProductFilterQuery>(DEFAULT_PRODUCT_FILTER);
  const { data: suppliers } = useContext(SupplierContext);
  const { data: groups } = useContext(ProductGroupContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelectGroup = (groupId: string) => {
    if (draftFilter.productGroupIds.some((id) => id === groupId)) return;
    else setDraftFilter({ ...draftFilter, productGroupIds: [...draftFilter.productGroupIds, groupId] });
  };

  const handleSelectSupplier = (supId: string) => {
    if (draftFilter.supplierIds.some((id) => id === supId)) return;
    else setDraftFilter({ ...draftFilter, supplierIds: [...draftFilter.supplierIds, supId] });
  };

  const handleDeleteGroup = (id: string) => {
    setDraftFilter({ ...draftFilter, productGroupIds: draftFilter.productGroupIds.filter((gId) => gId !== id) });
  };

  const handleDeleteSupplier = (id: string) => {
    setDraftFilter({ ...draftFilter, supplierIds: draftFilter.supplierIds.filter((supId) => supId !== id) });
  };

  const handleChangePriceInput = (value: number, type: 'min' | 'max') => {
    draftFilter.prices[type === 'min' ? 0 : 1] = value;
    setDraftFilter({ ...draftFilter });
  };

  const getLabel = (id: string, type: 'group' | 'supplier') => {
    if (type === 'group') return groups.find((g) => g.id === id)?.name;
    else return suppliers.find((g) => g.id === id)?.name;
  };

  const handlerSearchByFilter = () => {
    if (type === 'product') setProductFilter(draftFilter);
    else setInventoryFilter(draftFilter);
  };

  const handlerClearFiler = () => {
    if (type === 'product') setProductFilter(DEFAULT_PRODUCT_FILTER);
    else setInventoryFilter(DEFAULT_PRODUCT_FILTER);
    setDraftFilter(DEFAULT_PRODUCT_FILTER);
  };

  return (
    <Flex gap={4} justifyContent="space-between" alignItems="center">
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent minW="30vw">
          <DrawerHeader borderBottomWidth="2px">Bộ lọc</DrawerHeader>
          <DrawerBody>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel fontWeight={700}>Nhóm sản phẩm</FormLabel>
                <Select
                  placeholder="Tất cả"
                  onChange={(e) => handleSelectGroup(e.value)}
                  options={groups.filter((g) => g.level === 3).map((s) => ({ value: s.id, label: s.name }))}
                />
                <HStack spacing={1} wrap="wrap" mt={2}>
                  {draftFilter.productGroupIds.map((gId) => (
                    <Tag key={gId} colorScheme="green">
                      {getLabel(gId, 'group')}
                      <TagCloseButton onClick={() => handleDeleteGroup(gId)} />
                    </Tag>
                  ))}
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={700}>Nhà cung cấp</FormLabel>
                <Select
                  placeholder="Tất cả"
                  onChange={(e) => handleSelectSupplier(e.value)}
                  options={suppliers.map((s) => ({ value: s.id, label: s.name }))}
                />
                <HStack spacing={4} wrap="wrap" mt={2}>
                  {draftFilter.supplierIds.map((supId) => (
                    <Tag key={supId} colorScheme="blue">
                      {getLabel(supId, 'supplier')}
                      <TagCloseButton onClick={() => handleDeleteSupplier(supId)} />
                    </Tag>
                  ))}
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={700}>Giá</FormLabel>
                <Flex justifyContent="space-between" alignItems="center" gap={4}>
                  <NumberInput
                    value={formatCurrency(draftFilter.prices[0])}
                    min={0}
                    max={draftFilter.prices[1] - 1}
                    onChange={(_, val) => handleChangePriceInput(val, 'min')}
                  >
                    <NumberInputField />
                  </NumberInput>
                  -
                  <NumberInput
                    value={formatCurrency(draftFilter.prices[1])}
                    min={draftFilter.prices[0] + 1}
                    max={MAX_INPUT_PRICE}
                    onChange={(_, val) => handleChangePriceInput(val, 'max')}
                  >
                    <NumberInputField />
                  </NumberInput>
                </Flex>
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="2px">
            <Flex gap={4} justifyContent="space-between" alignItems="center" w="100%">
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  handlerClearFiler();
                  onClose();
                }}
                borderRadius={0}
                w="50%"
                borderColor="gray.800"
              >
                Xóa bộ lọc
              </Button>
              <Button
                colorScheme="cyan"
                color="white"
                borderRadius={0}
                w="50%"
                onClick={() => {
                  handlerSearchByFilter();
                  onClose();
                }}
              >
                Áp dụng
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <InputGroup w="40rem">
        <InputLeftElement>
          <IconButton
            title="Bộ lọc"
            aria-label="filter"
            icon={<FaFilter />}
            onClick={onOpen}
            bgColor="pink.500"
            color="white"
          />
        </InputLeftElement>
        <Input
          ml="3rem"
          pr="10rem"
          focusBorderColor="pink.500"
          placeholder="Tìm kiếm sản phẩm"
          borderRadius="none"
          onChange={(e) => setDraftFilter({ ...draftFilter, search: e.target.value })}
          onKeyDown={(e) => e.key === 'Enter' && handlerSearchByFilter()}
        />
        <InputRightElement w="10rem">
          <Button w="10rem" rounded={0} colorScheme="pink" color="white" onClick={handlerSearchByFilter}>
            Tìm kiếm
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export default ProductFilter;
