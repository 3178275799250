import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import vn from './languages/vn.json';
const resources = {
  vn: {
    translation: vn,
  },
} as const;

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'vn',
    debug: true,
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    resources,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  });
