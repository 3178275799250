import { NavLink, useLocation } from 'react-router-dom';

import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SidebarLinks: FC<{ routes: MenuRouteType[] }> = ({ routes = [] }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  const activeIcon = useColorModeValue('brand.500', 'white');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const activeRoute = (route: MenuRouteType) => pathname.includes(route.path);

  return (
    <>
      {routes
        .filter((r) => r.layout === 'admin')
        .map((route: MenuRouteType) => (
          <NavLink key={`${route.layout}/${route.path}`} to={route.path}>
            {route.icon ? (
              <Box>
                <HStack spacing={activeRoute(route) ? '22px' : '26px'} py="5px" ps="10px">
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box color={activeRoute(route) ? activeIcon : textColor} me="18px">
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={activeRoute(route) ? activeColor : textColor}
                      fontWeight={activeRoute(route) ? 'bold' : 'normal'}
                    >
                      {t(route.name)}
                    </Text>
                  </Flex>
                  <Box h="36px" w="4px" bg={activeRoute(route) ? brandColor : 'transparent'} borderRadius="5px" />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack spacing={activeRoute(route) ? '22px' : '26px'} py="5px" ps="10px">
                  <Text
                    me="auto"
                    color={activeRoute(route) ? activeColor : inactiveColor}
                    fontWeight={activeRoute(route) ? 'bold' : 'normal'}
                  >
                    {t(route.name)}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        ))}
    </>
  );
};

export default SidebarLinks;
