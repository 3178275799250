import { TransactionStatus, TransactionType } from 'models/transaction';
import { IconType } from 'react-icons';
import { FaBoxes, FaRegCheckCircle, FaTruckLoading } from 'react-icons/fa';
import { FaTruckArrowRight } from 'react-icons/fa6';
import { MdAssignmentReturn, MdCancel } from 'react-icons/md';
import { TbProgressAlert } from 'react-icons/tb';

export const NO_EXPIRE_DATE_ISO_STRING = '1970-01-01T00:00:00.000Z';
export const MAX_INPUT_PRODUCTS = 1000;
export const MAX_INPUT_PRICE = 1000000000;

export const NextValidStatus: Record<
  TransactionStatus,
  {
    type?: TransactionType;
    statuses: TransactionStatus[];
  }
> = {
  [TransactionStatus.NOT_PROCESS]: {
    type: TransactionType.EXPORT,
    statuses: [TransactionStatus.PREPARE, TransactionStatus.EXPORTED, TransactionStatus.CANCEL],
  },
  [TransactionStatus.PREPARE]: {
    type: TransactionType.EXPORT,
    statuses: [TransactionStatus.EXPORTED, TransactionStatus.CANCEL, TransactionStatus.PREPARE],
  },
  [TransactionStatus.EXPORTED]: {
    type: TransactionType.IMPORT,
    statuses: [TransactionStatus.IMPORTED],
  },
  [TransactionStatus.IMPORTED]: {
    type: TransactionType.IMPORT,
    statuses: [TransactionStatus.RETURN, TransactionStatus.DONE],
  },
  [TransactionStatus.RETURN]: {
    type: TransactionType.EXPORT,
    statuses: [TransactionStatus.DONE],
  },
  [TransactionStatus.CANCEL]: {
    statuses: [],
  },
  [TransactionStatus.DONE]: {
    statuses: [],
  },
};

export const StatusActions: Record<TransactionStatus, { label: string; icon: IconType }> = {
  [TransactionStatus.NOT_PROCESS]: {
    label: 'Xuất hàng cần xác nhận đơn hàng và chuyển sang Chuẩn bị hàng',
    icon: TbProgressAlert,
  },
  [TransactionStatus.PREPARE]: {
    label: 'Kho xuất hàng chuẩn bị hàng theo số lượng, sau đó chuyển trạng thái sang xuất hàng',
    icon: FaBoxes,
  },
  [TransactionStatus.EXPORTED]: {
    label:
      'Hàng đang được chuyển từ Kho xuất hàng sang Kho nhập hàng. Sản phẩm từ được điều chỉnh số lượng từ kho xuất hàng',
    icon: FaTruckArrowRight,
  },
  [TransactionStatus.IMPORTED]: {
    label: 'Kho nhập hàng nhận được hàng, sản phẩm được thêm số lượng vào kho',
    icon: FaTruckLoading,
  },
  [TransactionStatus.RETURN]: {
    label:
      'Kho nhập hàng kiểm tra số lượng, nếu có sản phẩm dư thừa hoặc không mong muốn, nhập hàng trả lại và chuyển về Kho xuất hàng',
    icon: MdAssignmentReturn,
  },
  [TransactionStatus.DONE]: {
    label: 'Đơn hàng được giao thành công',
    icon: FaRegCheckCircle,
  },
  [TransactionStatus.CANCEL]: {
    label: 'Giao dịch bị hủy bỏ',
    icon: MdCancel,
  },
};
