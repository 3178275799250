import { Icon } from '@chakra-ui/react';
import {
  MdHome,
  MdLock,
  MdPerson,
  MdProductionQuantityLimits,
  MdWarehouse
} from 'react-icons/md';

import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import RTL from 'views/admin/rtl';

import ProductPage from 'views/admin/products';
import WarehousePage from 'views/admin/warehouse';
import WarehouseInventory from 'views/admin/warehouse/inventories';
import WarehouseTransaction from 'views/admin/warehouse/transactions';
import SignIn from 'views/auth/signIn';

const routes: MenuRouteType[] = [
  {
    name: 'dashboard.name',
    layout: 'admin',
    path: 'default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  // {
  //   name: 'NFT Marketplace',
  //   layout: 'admin',
  //   path: 'nft-marketplace',
  //   icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: 'Data Tables',
  //   layout: 'admin',
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: 'data-tables',
  //   component: <DataTables />,
  // },
  {
    name: 'profile.name',
    layout: 'admin',
    path: 'profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
  {
    name: 'Sign In',
    layout: 'auth',
    path: 'sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignIn />,
  },
  {
    name: 'RTL Admin',
    layout: 'rtl',
    path: 'rtl-default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <RTL />,
  },
  {
    name: 'products.name',
    layout: 'admin',
    path: 'products',
    icon: <Icon as={MdProductionQuantityLimits} width="20px" height="20px" color="inherit" />,
    component: <ProductPage />,
    default: true,
  },
  {
    name: 'warehouse.name',
    layout: 'admin',
    path: 'warehouse',
    icon: <Icon as={MdWarehouse} width="20px" height="20px" color="inherit" />,
    component: <WarehousePage />,
    children: [
      {
        path: 'inventories',
        component: <WarehouseInventory />,
        default: true,
      },
      {
        path: 'transactions',
        component: <WarehouseTransaction />,
      },
    ],
  },
];

export default routes;
