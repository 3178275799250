import { WarehouseI } from './warehouse';

export enum TransactionStatus {
  'NOT_PROCESS' = 'NOT_PROCESS', // chưa xử lý
  'PREPARE' = 'PREPARE', // đang chuẩn bị
  'EXPORTED' = 'EXPORTED', // đã xuất hàng
  'IMPORTED' = 'IMPORTED', // trả hàng
  'RETURN' = 'RETURN', // trả hàng
  'DONE' = 'DONE', // xong
  'CANCEL' = 'CANCEL', // hủy giao dịch
}

export enum TransactionType {
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT', // nhập xuất hàng
  CHECK_INVENTORY = 'CHECK_INVENTORY', // kiểm kho
  ADJUST_DEFECTIVE_GOODS = 'ADJUST_DEFECTIVE_GOODS', // xuất hàng lỗi
  IMPORT_PRIMARY_WAREHOUSE = 'IMPORT_PRIMARY_WAREHOUSE', // nhập hàng kho chính
}

export type TransactionFilterI = {
  type?: TransactionType;
  status?: TransactionStatus;
  from?: string;
  to?: string;
};

export type TransactionI = {
  name?: string;
  id?: string;
  createdAt?: string;
  modifiedAt?: string;
  type: TransactionType;
  status?: TransactionStatus;
  description?: string;
  exportWarehouse?: WarehouseI;
  importWarehouse?: WarehouseI;
  exportWarehouseId?: string;
  importWarehouseId?: string;
  productTransactions?: TransactionItemI[];
};

export type ProductBriefI = {
  name: string;
};

export type TransactionItemI = {
  id?: string;
  productId: string;
  inventoryQuantity?: number;
  requestQuantity?: number;
  actualQuantity?: number;
  returnQuantity?: number;
  quantitationUnit: string;
  note?: string;
  expiry?: string;
};

export type InventoryHistoryItem = {
  quantity: number;
  type: TransactionType;
  transactionId: string;
  transactionName: string;
  exportWarehouse?: string;
  importWarehouse?: string;
  quantitationUnit: string;
  expiry?: string;
  modifiedAt: string;
};
