import { Box, Flex, Icon, Tag, Text, Tooltip } from '@chakra-ui/react';
import { TransactionStatus } from 'models/transaction';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { StatusTagColor } from 'utils/constants/color';
import { StatusActions } from 'utils/constants/transaction';

const TransactionStep: FC<{ status?: TransactionStatus }> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Box borderY="1px" borderColor="gray.300" py={5} mb={5}>
      <Text fontWeight={700} mb={2}>
        Quy trình giao/nhận hàng
      </Text>
      <Flex justifyContent="space-between" alignItems="center" gap={1}>
        {Object.values(TransactionStatus)
          .filter((s) => s !== TransactionStatus.CANCEL)
          .map((s, index) => (
            <Fragment key={s}>
              {!!index && <Icon as={FaLongArrowAltRight} />}
              <Tooltip label={StatusActions[s].label}>
                <Box
                  cursor="pointer"
                  p={2}
                  bgColor={status === s ? StatusTagColor[s] : 'transparent'}
                  borderWidth="1px"
                  width="150px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  <Tag bgColor={StatusTagColor[s]} color="white" px={4} textAlign="center" justifyContent="center">
                    {t(`warehouse.transaction.status.${s.toLocaleLowerCase()}`)}
                  </Tag>
                  <Icon as={StatusActions[s].icon} w={7} h={7} color={status === s ? 'white' : StatusTagColor[s]} />
                </Box>
              </Tooltip>
            </Fragment>
          ))}
      </Flex>
    </Box>
  );
};

export default TransactionStep;
