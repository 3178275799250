const NumberFormatter = new Intl.NumberFormat();

export const formatCurrency = (num: number) => NumberFormatter.format(num);

export const getSlug = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replaceAll('đ', 'd')
    .replace(/[\W_]+/g, ' ')
    .replace(/\s+/g, '-')
    .trim();
