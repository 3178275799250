import { useMutation, useQuery } from '@tanstack/react-query';
import { ProductFilterQuery } from 'models/product';
import { UpdateInventoryRequestI, WarehouseProductI } from 'models/warehouse';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_PRODUCT_FILTER } from 'utils/constants/default-value';
import { fetchWarehouseProducts, updateWarehouseInventories } from 'utils/services/apis/inventory.api';
import { GlobalAppContext } from './AppProvider';

export type WarehouseInventoryContextType = {
  data: WarehouseProductI[];
  filter: ProductFilterQuery;
  update: (productId: string, items: UpdateInventoryRequestI[]) => Promise<void>;
  setFilter: (filter: ProductFilterQuery) => void;
  warehouseId?: string;
};

export const WarehouseInventoryContext = createContext<WarehouseInventoryContextType>(
  {} as WarehouseInventoryContextType
);

const WarehouseInventoryProvider: FC<{ children: ReactNode; warehouseId?: string }> = ({ children, warehouseId }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const [filter, setFilter] = useState<ProductFilterQuery>(DEFAULT_PRODUCT_FILTER);
  const {
    data = [],
    refetch: fetchGroupsHandler,
    isFetching,
  } = useQuery<WarehouseProductI[]>({
    queryKey: ['warehouseProduct', warehouseId, filter],
    queryFn: async () => await fetchWarehouseProducts(warehouseId, filter),
    enabled: !!warehouseId,
  });

  const { mutateAsync: update, isPending } = useMutation({
    mutationFn: async (data: { productId: string; inventories: UpdateInventoryRequestI[] }) =>
      await updateWarehouseInventories(warehouseId, data.productId, data.inventories),
    onSuccess: async () => await fetchGroupsHandler(),
  });

  const loading = isFetching || isPending;

  useEffect(() => {
    toggleLoading(loading, 'inventory');
  }, [loading]);

  return (
    <WarehouseInventoryContext.Provider
      value={{
        data,
        update: (productId: string, inventories: UpdateInventoryRequestI[]) => update({ productId, inventories }),
        filter,
        setFilter,
        warehouseId,
      }}
    >
      {children}
    </WarehouseInventoryContext.Provider>
  );
};

export default WarehouseInventoryProvider;
