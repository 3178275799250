import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC } from 'react';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  totalRows: number;
  onPageIndexChange: (pageIndex: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
};

const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalRows,
  onPageIndexChange,
  pageSize,
  setPageSize,
  canNextPage,
  canPreviousPage,
}) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center" textTransform="capitalize">
      <Flex alignItems="center">Tổng: {totalRows}</Flex>
      <Flex alignItems="center">
        <Text flexShrink="0" mr={8}>
          Trang{' '}
          <Text fontWeight="bold" as="span">
            {currentPage}
          </Text>{' '}
          trong{' '}
          <Text fontWeight="bold" as="span">
            {totalPages}
          </Text>
        </Text>
      </Flex>
      <Flex alignItems="center" gap={1}>
        <Text flexShrink="0">Hiển thị:</Text>
        <Select w={32} value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
          {[10, 20, 50, 100, 200].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        trong 1 trang
      </Flex>
      <Flex gap={4} alignItems="center">
        <Text flexShrink="0">Trang:</Text>
        <NumberInput
          w={28}
          min={1}
          max={totalPages}
          onChange={(_, value) => {
            const page = value ? value - 1 : 0;
            onPageIndexChange(page);
          }}
          value={currentPage}
          defaultValue={currentPage}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Tooltip label="Trang đầu">
          <IconButton
            colorScheme="blue"
            aria-label="go-first"
            onClick={() => onPageIndexChange(0)}
            isDisabled={!canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
          />
        </Tooltip>
        <Tooltip label="Trang trước">
          <IconButton
            colorScheme="blue"
            aria-label="previous"
            onClick={() => onPageIndexChange(currentPage - 2)}
            isDisabled={!canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
        <MinusIcon />
        <Tooltip label="Trang tiếp theo">
          <IconButton
            colorScheme="blue"
            aria-label="next"
            onClick={() => onPageIndexChange(currentPage)}
            isDisabled={!canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Trang cuối">
          <IconButton
            colorScheme="blue"
            aria-label="go-last"
            onClick={() => onPageIndexChange(totalPages - 1)}
            isDisabled={!canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Pagination;
