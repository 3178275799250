import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react';
import ProductGroupProvider from 'components/providers/ProductGroupProvider';
import ProductProvider from 'components/providers/ProductProvider';
import SupplierProvider from 'components/providers/SupplierProvider';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateProductModal from './components/ProductModal/CreateProductModal';
import ProductTable from './components/ProductTable';

const ProductPage: FC = () => {
  const { t } = useTranslation();
  const [openCreateModal, toggleOpenModal] = useState(false);
  const [editingId, setEditingId] = useState<string>();

  const clickEditBtnHandler = (id: string) => {
    setEditingId(id);
    toggleOpenModal(true);
  };

  const closeCreationModalHandler = () => {
    setEditingId(undefined);
    toggleOpenModal(false);
  };

  return (
    <ProductGroupProvider>
      <SupplierProvider>
        <ProductProvider>
          <CreateProductModal id={editingId} open={openCreateModal} onClose={closeCreationModalHandler} />
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex w="100%" justifyContent="end" flexDirection="row" my="20px">
              <Button w="140px" minW="140px" variant="brand" fontWeight="500" onClick={() => toggleOpenModal(true)}>
                {t('buttons.addProduct')}
              </Button>
            </Flex>
            <SimpleGrid mb="20px" columns={1} spacing={{ base: '20px', xl: '20px' }}>
              <ProductTable onClickEdit={clickEditBtnHandler} />
            </SimpleGrid>
          </Box>
        </ProductProvider>
      </SupplierProvider>
    </ProductGroupProvider>
  );
};

export default ProductPage;
