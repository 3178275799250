import {
  Alert,
  AlertIcon,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Select,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { WarehouseContext } from 'components/providers/WarehouseProvider';
import { WarehouseTransactionContext } from 'components/providers/WarehouseTransactionProvider';
import { AuditField } from 'models/common';
import { TransactionI, TransactionStatus, TransactionType } from 'models/transaction';
import { SearchProductI } from 'models/warehouse';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validateImportPrimaryFormData } from 'utils/helper.ts/transaction';
import { searchWarehouseProductByIds } from 'utils/services/apis/inventory.api';
import SearchProductInput from './ProductSearch';
import AdjustProductCard from './TransactionProductCard/Adjust';
import CheckProductCard from './TransactionProductCard/Check';
import ExportProductCard from './TransactionProductCard/Export';
import ImportProductCard from './TransactionProductCard/Import';
import PrimaryProductCard from './TransactionProductCard/PrimaryImport';

type TransactionFormProps = {
  creatingType?: TransactionType; // undefined if view
  transaction?: TransactionI; // undefined if create
  onRefetchTransaction: () => Promise<void>;
};

const TransactionForm = forwardRef<{ submit: (nextStatus?: TransactionStatus) => void }, TransactionFormProps>(
  ({ creatingType, transaction, onRefetchTransaction }, ref) => {
    const { t } = useTranslation();
    const toast = useToast();
    const { data: warehouses } = useContext(WarehouseContext);
    const { warehouse, create, update: updateTransaction } = useContext(WarehouseTransactionContext);
    const type = creatingType ?? transaction?.type;
    const [selectedProductInfos, setSelectedProductInfos] = useState<SearchProductI[]>([]);
    const [loadingProductInfo, toggleLoadingInfo] = useState(false);

    useEffect(() => {
      // Fetch products info for Products Card
      if (transaction?.productTransactions.length && warehouse.id) {
        (async () => {
          toggleLoadingInfo(true);
          const productIds = transaction.productTransactions
            .map((item) => item.productId)
            .filter((v, i, arr) => arr.indexOf(v) === i);
          const productsInfo = await searchWarehouseProductByIds(warehouse.id, productIds);

          const productTransactions = transaction.productTransactions.map((item) => {
            const product = productsInfo.find((p) => p.id === item.productId);
            const existingInventory = product.inventories.find(
              ({ expiry, quantitationUnit }) =>
                item.quantitationUnit === quantitationUnit && (product.noExpiry || item.expiry === expiry)
            );

            return {
              ...item,
              inventoryQuantity: existingInventory?.quantity || 0,
              expiry: item.requestQuantity ? existingInventory?.expiry : item.expiry,
            };
          });

          setSelectedProductInfos(productsInfo);
          replace(productTransactions);
          toggleLoadingInfo(false);
        })();
      }
    }, [transaction, warehouse.id]);

    const initFormValue = useMemo(() => {
      let initValue: Omit<TransactionI, AuditField>;
      if (transaction) initValue = transaction;
      else
        initValue = {
          type: creatingType,
          exportWarehouseId: creatingType !== TransactionType.IMPORT ? warehouse?.id : undefined,
          importWarehouseId: creatingType === TransactionType.IMPORT ? warehouse?.id : undefined,
          productTransactions: [],
          description: '',
        };

      return initValue;
    }, [creatingType, transaction, warehouse?.id]);

    const {
      control,
      register,
      handleSubmit,
      formState: { errors, ...formState },
      ...restForm
    } = useForm<Omit<TransactionI, AuditField>>({ mode: 'onBlur', defaultValues: initFormValue });

    const { fields, append, remove, replace } = useFieldArray({
      control,
      keyName: 'formItemId',
      name: 'productTransactions',
    });

    const handleSelectProduct = (product: SearchProductI) => {
      if (selectedProductInfos.some((p) => p.id === product.id)) {
        toast({
          title: 'Sản phẩm đã tồn tại trong giao dịch',
          colorScheme: 'yellow',
          icon: <AlertIcon />,
        });
        return;
      }

      switch (type) {
        case TransactionType.EXPORT:
        case TransactionType.ADJUST_DEFECTIVE_GOODS:
          {
            if (!product.inventories.length || product.inventories.every((p) => p.quantity === 0)) {
              toast({
                title: 'Không có sản phẩm nào trong kho để xuất',
                colorScheme: 'yellow',
                icon: <AlertIcon />,
              });
              return;
            }

            const existingInventory = product.inventories[0];
            append({
              productId: product.id,
              quantitationUnit: existingInventory.quantitationUnit,
              inventoryQuantity: existingInventory.quantity,
              expiry: existingInventory.expiry,
              actualQuantity: 1,
            });
          }
          break;

        case TransactionType.IMPORT:
        case TransactionType.IMPORT_PRIMARY_WAREHOUSE:
          append({
            productId: product.id,
            quantitationUnit: product.baseUnit,
            inventoryQuantity:
              product.inventories.find((item) => item.quantitationUnit === product.baseUnit)?.quantity || 0,
            requestQuantity: type === TransactionType.IMPORT ? 1 : undefined,
            actualQuantity: type === TransactionType.IMPORT_PRIMARY_WAREHOUSE ? 1 : undefined,
          });
          break;

        case TransactionType.CHECK_INVENTORY:
          if (!product.inventories.length || product.inventories.every((p) => p.quantity === 0)) {
            toast({
              title: 'Không có sản phẩm nào trong kho để chỉnh sửa',
              colorScheme: 'yellow',
              icon: <AlertIcon />,
            });
            return;
          }

          append(
            product.inventories.map((inventory) => ({
              productId: product.id,
              quantitationUnit: inventory.quantitationUnit,
              inventoryQuantity: inventory.quantity,
              expiry: inventory.expiry,
              actualQuantity: inventory.quantity,
            }))
          );
          break;
      }

      setSelectedProductInfos([...selectedProductInfos, product]);
    };

    const showError = (msg: string) =>
      toast({
        colorScheme: 'red',
        icon: <AlertIcon />,
        title: msg,
      });

    const submitHandler: (newStatus?: TransactionStatus) => SubmitHandler<TransactionI> =
      (newStatus) => async (data) => {
        if (
          newStatus !== TransactionStatus.CANCEL &&
          !data.productTransactions.reduce((sum, v) => sum + v.requestQuantity, 0) &&
          !data.productTransactions?.reduce((sum, v) => sum + v.actualQuantity, 0)
        ) {
          showError('Không có sản phẩm nào được chọn');
          return;
        }

        if (newStatus) {
          if (newStatus === TransactionStatus.RETURN) {
            if (data.productTransactions.every((item) => !item.returnQuantity)) {
              showError('Cần điền ít nhất 1 ô số lượng trả hàng');

              return;
            }
          }
          await updateTransaction({ ...data, status: newStatus });
        } else {
          if (type === TransactionType.IMPORT_PRIMARY_WAREHOUSE) {
            const errs = validateImportPrimaryFormData(data.productTransactions, selectedProductInfos);
            if (errs.length) {
              errs.forEach((err) => showError(err));
              return;
            }
          }
          console.log(data);
          await create(data);
        }
        await onRefetchTransaction();
      };

    // const fillInventoriesHandler = async () => {
    //   const requestProductIds = fields.filter((p) => p.requestQuantity > 0).map((p) => p.productId);
    //   if (requestProductIds.length === 0) return;
    //   const products = await fetchWarehouseProducts(warehouse?.id, undefined, requestProductIds);
    //   const newItems: TransactionItemI[] = [];

    //   for (const product of products) {
    //     const fieldIndex = fields.findIndex((item) => !!item.requestQuantity && item.productId === product.id);
    //     const requestItem = fields[fieldIndex];
    //     if (!product.productInventories.length) {
    //       newItems.push({
    //         ...fields[fieldIndex],
    //         actualQuantity: 0,
    //       });
    //       continue;
    //     }

    //     let remainQuantity = requestItem.requestQuantity;
    //     for (const inventory of product.productInventories) {
    //       const actualQuantity = remainQuantity > inventory.quantity ? inventory.quantity : remainQuantity;
    //       if (remainQuantity === requestItem.requestQuantity) {
    //         newItems.push({
    //           ...fields[fieldIndex],
    //           actualQuantity,
    //           expiry: inventory.expiry,
    //         });
    //       } else {
    //         newItems.push({
    //           productId: product.id,
    //           actualQuantity,
    //           expiry: inventory.expiry,
    //           quantitationUnit: inventory.quantitationUnit,
    //         });
    //       }

    //       remainQuantity -= actualQuantity;
    //       if (remainQuantity === 0) break;
    //     }
    //   }
    //   replace(newItems);
    // };

    const handleDeleteProductCard = (productId: string) => {
      remove(
        fields
          .filter((item) => item.productId === productId)
          .map(({ formItemId }) => fields.findIndex((item) => item.formItemId === formItemId))
      );
      setSelectedProductInfos(selectedProductInfos.filter((p) => p.id !== productId));
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: (status?: TransactionStatus) => handleSubmit(submitHandler(status))(),
      }),
      [handleSubmit]
    );

    return (
      <FormProvider
        control={control}
        register={register}
        handleSubmit={handleSubmit}
        formState={{ errors, ...formState }}
        {...restForm}
      >
        <Stack spacing={4}>
          {[TransactionType.EXPORT, TransactionType.IMPORT].includes(type) && (
            <Grid templateColumns="repeat(2, 1fr)" gap={3}>
              <GridItem width="100%">
                <FormControl isInvalid={!!errors.exportWarehouseId?.type}>
                  <FormLabel>{t('warehouse.fields.exportWarehouse')}</FormLabel>
                  <Select
                    {...register('exportWarehouseId', {
                      required: true,
                      setValueAs: (v) => (v === 'na' ? undefined : v),
                    })}
                    isDisabled={type !== TransactionType.IMPORT || !!transaction}
                    size="md"
                  >
                    <option value="na" style={{ background: 'white' }}>
                      {t('common.na')}
                    </option>
                    {warehouses
                      .filter((wh) => type !== TransactionType.IMPORT || wh.id !== warehouse?.id)
                      .filter((wh) => wh.isPrimary)
                      .map((wh) => (
                        <option key={wh.id} value={wh.id} style={{ background: 'white' }}>
                          {wh.name}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.exportWarehouseId?.type === 'required' &&
                      t('messages.validations.fieldRequired', { field: t('warehouse.fields.exportWarehouse') })}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem width="100%">
                <FormControl isInvalid={!!errors.importWarehouseId?.type}>
                  <FormLabel>{t('warehouse.fields.importWarehouse')}</FormLabel>

                  <Select
                    {...register('importWarehouseId', {
                      required: true,
                      setValueAs: (v) => (v === 'na' ? undefined : v),
                    })}
                    size="md"
                    isDisabled={type === TransactionType.IMPORT || !!transaction}
                  >
                    <option value="na" style={{ background: 'white' }}>
                      {t('common.na')}
                    </option>
                    {warehouses
                      .filter((wh) => type === TransactionType.IMPORT || wh.id !== warehouse?.id)
                      .map((wh) => (
                        <option key={wh.id} value={wh.id} style={{ background: 'white' }}>
                          {wh.name}
                        </option>
                      ))}
                  </Select>

                  <FormErrorMessage>
                    {errors.importWarehouseId?.type === 'required' &&
                      t('messages.validations.fieldRequired', { field: t('warehouse.fields.importWarehouse') })}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          )}

          {/* <FormControl isInvalid={!!errors.description?.type}>
            <FormLabel>{t('common.description')}</FormLabel>
            <Textarea rows={2} {...register('description', { maxLength: 1000, disabled: !!transaction })}></Textarea>
            <FormErrorMessage>
              {errors.description?.type === 'max' && t('messages.validations.maxLength', { length: 1000 })}
            </FormErrorMessage>
          </FormControl> */}

          {!transaction && <SearchProductInput onSelectProduct={handleSelectProduct} />}

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size="md" mt={2}>
              Danh sách sản phẩm
            </Heading>
            {/* {[TransactionStatus.NOT_PROCESS, TransactionStatus.PREPARE].includes(transaction?.status) &&
              type === TransactionType.EXPORT &&
              fields.some((field) => !!field.requestQuantity) && (
                <Tooltip label="Tự động điền số lượng với HSD GẦN NHẤT được ưu tiên chọn trước">
                  <Button
                    rightIcon={<FaRobot />}
                    bgColor="pink"
                    _hover={{ bgColor: 'pink.300' }}
                    onClick={fillInventoriesHandler}
                  >
                    Chọn tự động từ kho
                  </Button>
                </Tooltip>
              )} */}
          </Flex>
          {errors?.productTransactions?.root?.type === 'required' && (
            <Alert status="warning">
              <AlertIcon />
              Vui lòng chọn sản phẩm
            </Alert>
          )}
          {errors?.productTransactions?.root?.type === 'maxLength' && (
            <Alert status="warning">
              <AlertIcon />
              Quá số lượng cho phép
            </Alert>
          )}
          <Stack spacing={4}>
            {loadingProductInfo && <Spinner size="xl" alignSelf="center" />}
            {selectedProductInfos.map((product) =>
              type === TransactionType.CHECK_INVENTORY ? (
                <CheckProductCard
                  key={product.id}
                  onDeleteProduct={() => handleDeleteProductCard(product.id)}
                  product={product}
                  transactionItems={fields
                    .map((field, index) => ({ ...field, index }))
                    .filter((item) => item.productId === product.id)}
                  status={transaction?.status}
                />
              ) : type === TransactionType.ADJUST_DEFECTIVE_GOODS ? (
                <AdjustProductCard
                  key={product.id}
                  onDeleteProduct={() => handleDeleteProductCard(product.id)}
                  product={product}
                  transactionItems={fields
                    .map((field, index) => ({ ...field, index }))
                    .filter((item) => item.productId === product.id)}
                  status={transaction?.status}
                  onAddItem={append}
                  onDeleteItem={(idx) => remove(idx)}
                />
              ) : type === TransactionType.IMPORT_PRIMARY_WAREHOUSE ? (
                <PrimaryProductCard
                  key={product.id}
                  status={transaction?.status}
                  onAddItem={append}
                  onDeleteItem={(index) => remove(index)}
                  onDeleteProduct={() => handleDeleteProductCard(product.id)}
                  product={product}
                />
              ) : type === TransactionType.IMPORT ? (
                <ImportProductCard
                  key={product.id}
                  onAddItem={append}
                  onDeleteItem={(idx) => remove(idx)}
                  product={product}
                  status={transaction?.status}
                  onDeleteProduct={() => handleDeleteProductCard(product.id)}
                />
              ) : (
                <ExportProductCard
                  key={product.id}
                  onAddItem={append}
                  onDeleteItem={(idx) => remove(idx)}
                  product={product}
                  status={transaction?.status}
                  onDeleteProduct={() => handleDeleteProductCard(product.id)}
                />
              )
            )}
          </Stack>
        </Stack>
      </FormProvider>
    );
  }
);

export default TransactionForm;
