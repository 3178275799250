import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { AuditField } from 'models/common';
import { TransactionI, TransactionItemI, TransactionStatus } from 'models/transaction';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { FC, useMemo } from 'react';
import { Controller, FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type TransactionItemWithId = FieldArrayWithId<Omit<TransactionI, AuditField>, 'productTransactions', 'formItemId'> & {
  index: number;
};

type ExportProductCardProps = {
  product: SearchProductI;
  onAddItem: (item: TransactionItemI) => void;
  onDeleteItem: (index: number) => void;
  onDeleteProduct: () => void;
  status?: TransactionStatus;
};

const ExportProductCard: FC<ExportProductCardProps> = ({
  onAddItem,
  onDeleteItem,
  onDeleteProduct,
  product,
  status,
}) => {
  const { t } = useTranslation();
  const { watch, register, setValue, control } = useFormContext();
  const transactionProducts: TransactionItemWithId[] = useWatch({
    name: 'productTransactions',
    control,
  });
  const transactionItems = transactionProducts
    .map((field, index) => ({ ...field, index }))
    .filter((item) => item.productId === product.id);

  const stock = useMemo(
    () =>
      product.inventories
        .reduce((arr: InventoryQuantityI[], inventory) => {
          const idx = arr.findIndex((item) => item.quantitationUnit === inventory.quantitationUnit);
          if (idx !== -1) arr[idx].quantity += inventory.quantity;
          else arr.push({ ...inventory });
          return arr;
        }, [])
        .map(({ quantitationUnit, quantity }) => `${quantity} ${quantitationUnit}`)
        .join(', '),
    [product.inventories]
  );

  const remainInventories = product.inventories.filter((inventoryItem) =>
    transactionItems.every(
      (tranItem) =>
        tranItem.quantitationUnit !== inventoryItem.quantitationUnit || tranItem.expiry !== inventoryItem.expiry
    )
  );

  const handleAddUnitForExport = () =>
    onAddItem({
      productId: product.id,
      quantitationUnit: remainInventories[0]?.quantitationUnit,
      expiry: remainInventories[0]?.expiry,
      actualQuantity: 1,
      inventoryQuantity: remainInventories[0]?.quantity,
    });

  const isEditingActualQuantity = [undefined, TransactionStatus.NOT_PROCESS, TransactionStatus.PREPARE].includes(
    status
  );
  const showRequestQuantityInfo = Boolean(status) && transactionItems.some((item) => Boolean(item.requestQuantity));
  const showReturnQuantityInfo = [TransactionStatus.DONE, TransactionStatus.RETURN].includes(status);
  const showSelectInventories =
    !status ||
    ([TransactionStatus.PREPARE, TransactionStatus.NOT_PROCESS].includes(status) &&
      Boolean(transactionItems.some((item) => Boolean(item.requestQuantity))));

  console.log('transactionItems', transactionItems);
  return (
    <Card direction="column" variant="outline" key={product.id}>
      <CardBody>
        <Stack>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap={2} alignItems="center">
              <Image src={product.image} w="3rem" fallbackSrc="https://via.placeholder.com/150" />
              <Flex direction="column" justifyContent="space-between">
                <Text noOfLines={1}>{product.name}</Text>
                <Flex gap={1}>
                  <Tag colorScheme={product.inventories.length ? 'purple' : 'red'}>
                    <TagLabel>
                      {t('warehouse.fields.stock')}: {stock || 0}
                    </TagLabel>
                  </Tag>
                  {product.noExpiry && (
                    <Tooltip label="Sản phẩm này không cần Hạn sử dụng" hasArrow bgColor="orange.400" placement="top">
                      <Tag colorScheme="orange">Không HSD</Tag>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </Flex>
            {!status && (
              <IconButton
                tabIndex={-1}
                colorScheme="red"
                aria-label="delete-item"
                icon={<DeleteIcon />}
                onClick={onDeleteProduct}
              />
            )}
          </Flex>
          {transactionItems.map((item) => (
            <Flex key={item.formItemId} justifyContent="flex-start" alignItems="center" gap={2}>
              {isEditingActualQuantity && (
                <Flex alignItems="center" justifyContent="space-between" gap={1}>
                  <Text fontWeight={700} w="6rem">
                    SL Chuyển
                  </Text>
                  <NumberInput
                    w="10rem"
                    min={1}
                    max={watch(`productTransactions.${item.index}.inventoryQuantity`)}
                    allowMouseWheel
                  >
                    <NumberInputField
                      {...register(`productTransactions.${item.index}.actualQuantity`, {
                        min: 1,
                        max: watch(`productTransactions.${item.index}.inventoryQuantity`),
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              )}

              {!isEditingActualQuantity && (
                <Flex alignItems="center">
                  <Text w="6rem">{status === TransactionStatus.PREPARE ? 'Dự kiến' : 'Đã chuyển'}:</Text>
                  <Text fontWeight="700" w="5rem">
                    {item.actualQuantity} {item.quantitationUnit}
                  </Text>
                  <Tag colorScheme={product.noExpiry ? undefined : 'cyan'} w="8rem">
                    {!product.noExpiry && `HSD ${dayjs(item.expiry).format('DD/MM/YYYY')}`}
                  </Tag>
                </Flex>
              )}

              {showRequestQuantityInfo && (
                <Tag
                  colorScheme={Boolean(item.requestQuantity) ? 'gray' : ''}
                  minW="8rem"
                  fontWeight={700}
                  variant="outline"
                >
                  {Boolean(item.requestQuantity) && `Yêu cầu ${item.requestQuantity} ${item.quantitationUnit}`}
                </Tag>
              )}

              {showReturnQuantityInfo && Boolean(item.returnQuantity) && (
                <Tag colorScheme="purple" minW="7rem" fontWeight={700}>
                  Trả lại {item.returnQuantity || 0} {item.quantitationUnit}
                </Tag>
              )}

              {showSelectInventories && (
                <>
                  {remainInventories.filter((inventory) => inventory.quantitationUnit === item.quantitationUnit)
                    .length ? (
                    <Box width="20rem">
                      <Controller
                        name={`productTransactions.${item.index}.expiry`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={{
                              label:
                                (product.noExpiry ? '' : `HSD: ${dayjs(value).format('DD/MM/YYYY')} - `) +
                                `SL kho: ${watch(`transactionItems.${item.index}.inventoryQuantity`)} ${
                                  item.quantitationUnit
                                }`,
                              value: {
                                expiry: value,
                                quantity: watch(`productTransactions.${item.index}.inventoryQuantity`),
                              },
                            }}
                            onChange={({ value: { expiry, quantity } }) => {
                              onChange(expiry);
                              setValue(`productTransactions.${item.index}.inventoryQuantity`, quantity);
                            }}
                            options={remainInventories
                              .filter((inventory) => inventory.quantitationUnit === item.quantitationUnit)
                              .map(({ expiry, quantity, quantitationUnit }) => ({
                                label:
                                  (product.noExpiry ? '' : `HSD: ${dayjs(expiry).format('DD/MM/YYYY')} - `) +
                                  `SL kho: ${quantity} ${quantitationUnit}`,
                                value: { expiry, quantity },
                              }))}
                          />
                        )}
                      />
                    </Box>
                  ) : (
                    <Tag colorScheme="red">Không đủ sản phẩm với đơn vị này</Tag>
                  )}
                </>
              )}

              {isEditingActualQuantity && !item.requestQuantity && transactionItems.length > 1 && (
                <Box w="2rem">
                  <IconButton
                    colorScheme="orange"
                    color="white"
                    aria-label="delete-trans-item"
                    icon={<DeleteIcon />}
                    onClick={() => onDeleteItem(item.index)}
                  />
                </Box>
              )}
            </Flex>
          ))}
          {isEditingActualQuantity && Boolean(remainInventories.length) && (
            <Button
              w="20rem"
              colorScheme="cyan"
              color="white"
              aria-label="new-inventory"
              onClick={handleAddUnitForExport}
            >
              Xuất thêm loại khác
            </Button>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ExportProductCard;
