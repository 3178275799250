import axios from 'axios';
import { ProductFilterQuery } from 'models/product';
import { InventoryHistoryItem } from 'models/transaction';
import { ProductInventoryI, SearchProductI, UpdateInventoryRequestI, WarehouseProductI } from 'models/warehouse';
import { DEFAULT_PRODUCT_FILTER } from 'utils/constants/default-value';
import { ENDPOINTS } from 'utils/constants/endpoint';

export const fetchWarehouseProducts = async (
  warehouseId: string,
  filter: ProductFilterQuery = DEFAULT_PRODUCT_FILTER,
  productIds: string[] = []
) =>
  await axios
    .get<WarehouseProductI[]>(`${ENDPOINTS.inventories}/${warehouseId}/products`, {
      params: {
        ...filter,
        productGroupIds: filter.productGroupIds.join(','),
        supplierIds: filter.supplierIds.join(','),
        prices: filter.prices.join(','),
        productIds,
      },
    })
    .then((resp) => resp.data);

export const searchWarehouseProducts = async (warehouseId: string, search?: string) =>
  await axios
    .get<SearchProductI[]>(`${ENDPOINTS.inventories}/${warehouseId}/search`, {
      params: { search },
    })
    .then((resp) => {
      return resp.data;
    });

export const searchWarehouseProductByIds = async (warehouseId: string, productIds: string[]) =>
  await axios
    .get<SearchProductI[]>(`${ENDPOINTS.inventories}/${warehouseId}/searchByIds`, {
      params: { productIds },
    })
    .then((resp) => {
      return resp.data;
    });

export const getProductInventories = async (warehouseId: string, productId: string) =>
  await axios
    .get<ProductInventoryI[]>(`${ENDPOINTS.inventories}/${warehouseId}/products/${productId}`)
    .then((resp) => resp.data);

export const getInventoryHistories = async (warehouseId: string, productId: string) =>
  await axios
    .get<InventoryHistoryItem[]>(`${ENDPOINTS.inventories}/${warehouseId}/products/${productId}/history`)
    .then((resp) => resp.data);

export const updateWarehouseInventories = async (
  warehouseId: string,
  productId: string,
  inventories: UpdateInventoryRequestI[]
) =>
  await axios
    .put(`${ENDPOINTS.inventories}/${warehouseId}/products/${productId}`, {
      inventories,
    })
    .then((resp) => resp.data);
