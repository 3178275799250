import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { SupplierContext } from 'components/providers/SupplierProvider';
import { CreateSupplierRequestI } from 'models/supplier';
import { FC, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoCloseCircle } from 'react-icons/io5';
import { getSlug } from 'utils/helper.ts/common';

const CreateSupplierModal: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { create } = useContext(SupplierContext);
  const toast = useToast();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateSupplierRequestI>();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const createSupplierHandler = async (data: CreateSupplierRequestI) => {
    const res = await create(data);
    if (res.id) {
      toast({
        status: 'success',
        title: 'Thêm thành công',
        icon: <CheckCircleIcon />,
      });
      onClose();
    } else {
      toast({
        status: 'error',
        title: 'Thêm thất bại',
        icon: <IoCloseCircle />,
      });
    }
  };

  return (
    <Modal onClose={onClose} closeOnOverlayClick={false} isOpen={isOpen} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap={2}>
          {t('buttons.add')} {t('supplier.name')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <FormControl display="flex" isInvalid={!!errors.name?.type}>
              <FormLabel w="12rem">{t('supplier.fields.name')}</FormLabel>
              <Box w="calc(100% - 12rem)">
                <Input
                  {...register('name', {
                    required: true,
                    onChange: (e) => {
                      setValue('code', getSlug(e.target.value));
                    },
                  })}
                  placeholder={t('supplier.fields.name')}
                />
                <FormErrorMessage>
                  {errors.name?.type === 'required' &&
                    t('messages.validations.fieldRequired', { field: t(`supplier.fields.name`) })}
                </FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl display="flex" isInvalid={!!errors.code?.type}>
              <FormLabel w="12rem">{t('supplier.fields.code')}</FormLabel>
              <Box w="calc(100% - 12rem)">
                <Input
                  bgColor="gray.200"
                  placeholder={t('supplier.fields.code')}
                  color="gray.600"
                  isReadOnly
                  {...register('code', {
                    required: true,
                  })}
                />
                <FormErrorMessage>
                  {errors.code?.type === 'required' &&
                    t('messages.validations.fieldRequired', { field: t(`supplier.fields.code`) })}
                </FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl display="flex" isInvalid={!!errors.phone?.type}>
              <FormLabel w="12rem">{t('supplier.fields.phone')}</FormLabel>
              <Box w="calc(100% - 12rem)">
                <Input
                  placeholder={t('supplier.fields.phone')}
                  {...register('phone', {
                    pattern: {
                      value: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
                      message: 'wrongFormat',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.phone?.message === 'wrongFormat' && t('messages.validations.wrongPhoneFormat')}
                </FormErrorMessage>
              </Box>
            </FormControl>
            <FormControl display="flex" isInvalid={!!errors.description?.type}>
              <FormLabel w="12rem">{t('supplier.fields.description')}</FormLabel>
              <Box w="calc(100% - 12rem)">
                <Textarea
                  maxLength={250}
                  placeholder={t('supplier.fields.description')}
                  {...register('description', {
                    maxLength: 250,
                  })}
                />
                <FormErrorMessage>
                  {errors.description?.type && t('messages.validations.maxLength', { length: 250 })}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between">
          <Button onClick={() => handleSubmit(createSupplierHandler)()} colorScheme="blue">
            {t('buttons.save')}
          </Button>
          <Button onClick={onClose} colorScheme="gray">
            {t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSupplierModal;
