import { Box, Flex, Image, Spinner } from '@chakra-ui/react';
import ShynhImage from 'assets/img/shynhHouseIcon.png';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';

const ShynhLoader: FC<{ loading: boolean }> = ({ loading }) => {
  useEffect(() => {
    if (loading) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'scroll'
  }, [loading]);

  return (
    <Box
      bgColor="rgba(192, 192, 192, 0.6)"
      position="fixed"
      userSelect="none"
      w="100vw"
      h="100vh"
      display={loading ? 'flex' : 'none'}
      alignItems="center"
      justifyContent="center"
      zIndex={9999}
      gap={2}
    >
      <Flex alignItems="center" gap={5} flexDirection="column">
        <motion.div
          initial={{ opacity: 1, scale: 0.8 }}
          animate={{ scale: 1.2 }}
          transition={{
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 2,
          }}
        >
          <Image h="auto" src={ShynhImage} textDecorationThickness="xl" />
        </motion.div>
        <Spinner size="xl" color="pink.500" />
      </Flex>
    </Box>
  );
};

export default ShynhLoader;
