import { Alert, SimpleGrid } from '@chakra-ui/react';
import { GlobalAppContext } from 'components/providers/AppProvider';
import WarehouseInventoryProvider from 'components/providers/WarehouseInventoryProvider';
import { FC, useContext } from 'react';
import ProductTable from './InventoriesTable';
import SupplierProvider from 'components/providers/SupplierProvider';
import ProductGroupProvider from 'components/providers/ProductGroupProvider';

const WarehouseInventory: FC = () => {
  const { warehouse } = useContext(GlobalAppContext);

  return warehouse ? (
    <ProductGroupProvider>
      <SupplierProvider>
        <WarehouseInventoryProvider warehouseId={warehouse?.id}>
          <SimpleGrid mb="20px" columns={1} spacing={{ base: '20px', xl: '20px' }}>
            <ProductTable warehouseId={warehouse.id} />
          </SimpleGrid>
        </WarehouseInventoryProvider>
      </SupplierProvider>
    </ProductGroupProvider>
  ) : (
    <Alert>Vui lòng chọn Kho</Alert>
  );
};

export default WarehouseInventory;
