import { useMutation, useQuery } from '@tanstack/react-query';
import { FilterQuery } from 'models/common';
import { CrudContextType } from 'models/context-type';
import { CreateProductGroupRequestI, ProductGroupI } from 'models/product-group';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { createProductGroup, fetchProductGroups } from 'utils/services/apis/product-group.api';
import { GlobalAppContext } from './AppProvider';

type FilterType = {
  filter: FilterQuery;
  setFilter: (filter: FilterQuery) => void;
};
export type ProductGroupContextType = CrudContextType<ProductGroupI, CreateProductGroupRequestI> & FilterType;
export const ProductGroupContext = createContext<ProductGroupContextType>({} as ProductGroupContextType);

const ProductGroupProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const [filter, setFilter] = useState<FilterQuery>({});
  const {
    data = [],
    refetch: fetchGroupsHandler,
    isFetching,
  } = useQuery<ProductGroupI[]>({
    queryKey: ['productGroup'],
    queryFn: () => fetchProductGroups({}),
  });

  const { mutateAsync: create, isPending: isCreatePending } = useMutation({
    mutationFn: async (group: CreateProductGroupRequestI) => await createProductGroup(group),
    onSuccess: async () => await fetchGroupsHandler(),
  });

  const loading = isFetching || isCreatePending;

  useEffect(() => {
    toggleLoading(loading, 'productGroup');
  }, [loading]);

  return (
    <ProductGroupContext.Provider
      value={{
        data,
        create,
        filter,
        setFilter,
      }}
    >
      {children}
    </ProductGroupContext.Provider>
  );
};

export default ProductGroupProvider;
