import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import DatePicker from 'components/datepicker/ReactDatePicker';
import dayjs from 'dayjs';
import { AuditField } from 'models/common';
import { TransactionI, TransactionItemI, TransactionStatus } from 'models/transaction';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { FC, useMemo } from 'react';
import { Controller, FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { MAX_INPUT_PRODUCTS } from 'utils/constants/transaction';
import ProductInfo from './ProductInfo';

type TransactionItemWithId = FieldArrayWithId<Omit<TransactionI, AuditField>, 'productTransactions', 'formItemId'> & {
  index: number;
};

type PrimaryImportProductCardProps = {
  product: SearchProductI;
  onAddItem: (item: TransactionItemI) => void;
  onDeleteItem: (index: number) => void;
  onDeleteProduct: () => void;
  status?: TransactionStatus;
};

const PrimaryImportProductCard: FC<PrimaryImportProductCardProps> = ({
  onAddItem,
  onDeleteItem,
  onDeleteProduct,
  product,
  status,
}) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext();
  const transactionProducts: TransactionItemWithId[] = useWatch({
    name: 'productTransactions',
    control,
  });
  const transactionItems = transactionProducts
    .map((field, index) => ({ ...field, index }))
    .filter((item) => item.productId === product.id);

  const units = [product.baseUnit, ...product.quantitationUnits.map((u) => u.unitName)];
  const unitOptions = product.noExpiry
    ? units.filter((u) => transactionItems.every((x) => x.quantitationUnit !== u))
    : units;

  const stock = useMemo(
    () =>
      product.inventories
        .reduce((arr: InventoryQuantityI[], inventory) => {
          const idx = arr.findIndex((item) => item.quantitationUnit === inventory.quantitationUnit);
          if (idx !== -1) arr[idx].quantity += inventory.quantity;
          else arr.push({ ...inventory });
          return arr;
        }, [])
        .map(({ quantitationUnit, quantity }) => `${quantity} ${quantitationUnit}`)
        .join(', '),
    [product.inventories]
  );

  const handleAddUnitForImport = () =>
    onAddItem({
      productId: product.id,
      quantitationUnit: unitOptions[0],
      expiry: product.noExpiry ? undefined : dayjs().startOf('D').toISOString(),
      actualQuantity: 1,
    });

  return (
    <Card direction="column" variant="outline" key={product.id}>
      <CardBody>
        <Stack>
          <ProductInfo product={product} onDeleteProduct={!status ? onDeleteProduct : undefined} />
          {transactionItems.map((item) => (
            <Flex key={item.formItemId} justifyContent="flex-start" alignItems="center" gap={2}>
              {!!status && (
                <Flex alignItems="center">
                  <Text w="6rem">SL Nhập:</Text>
                  <Text fontWeight="700" w="5rem">
                    {item.actualQuantity} {item.quantitationUnit}
                  </Text>
                  <Tag colorScheme={product.noExpiry ? undefined : 'cyan'} w="8rem">
                    {!product.noExpiry && `HSD ${dayjs(item.expiry).format('DD/MM/YYYY')}`}
                  </Tag>
                </Flex>
              )}

              {!status && (
                <Flex alignItems="center" justifyContent="space-between" gap={1}>
                  <Text fontWeight={700} w="6rem">
                    SL Nhập
                  </Text>
                  <NumberInput
                    w="10rem"
                    min={1}
                    max={
                      product.quantitationUnits.find((u) => u.unitName === item.quantitationUnit)?.totalInHigherUnit ||
                      MAX_INPUT_PRODUCTS
                    }
                    allowMouseWheel
                  >
                    <NumberInputField
                      {...register(`productTransactions.${item.index}.actualQuantity`, {
                        min: 1,
                        max:
                          product.quantitationUnits.find((u) => u.unitName === item.quantitationUnit)
                            ?.totalInHigherUnit || MAX_INPUT_PRODUCTS,
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              )}

              {!status && units.length > 1 && (
                <Box width="13rem">
                  {product.quantitationUnits.length ? (
                    <Controller
                      name={`productTransactions.${item.index}.quantitationUnit`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={{ value, label: value }}
                          onChange={(e) => onChange(e.value)}
                          options={unitOptions.map((unit) => ({
                            value: unit,
                            label: unit,
                          }))}
                        />
                      )}
                    />
                  ) : (
                    product.baseUnit
                  )}
                </Box>
              )}

              {!status && !product.noExpiry && (
                <Box w="10rem" zIndex={1}>
                  <Controller
                    name={`productTransactions.${item.index}.expiry`}
                    defaultValue={dayjs().startOf('D').toISOString()}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        minDate={dayjs().startOf('D').toDate()}
                        popperPlacement="right-start"
                        dateFormat="dd/MM/yyyy"
                        selected={new Date(value)}
                        onChange={(e: Date) => onChange(dayjs(e).startOf('D').toISOString())}
                      />
                    )}
                  />
                </Box>
              )}

              {!status && transactionItems.length > 1 && (
                <Box w="2rem">
                  <IconButton
                    colorScheme="orange"
                    color="white"
                    aria-label="delete-trans-item"
                    icon={<DeleteIcon />}
                    onClick={() => onDeleteItem(item.index)}
                  />
                </Box>
              )}
            </Flex>
          ))}
          {!status && (!product.noExpiry || !!unitOptions.length) && (
            <Button w="10rem" colorScheme="cyan" color="white" aria-label="new-unit" onClick={handleAddUnitForImport}>
              Thêm{' '}
              {[unitOptions.length ? 'Đơn vị' : '', product.noExpiry ? '' : 'HSD'].filter((opt) => !!opt).join(', ')}{' '}
              khác
            </Button>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default PrimaryImportProductCard;
