import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { ProductContext } from 'components/providers/ProductProvider';
import { SupplierContext } from 'components/providers/SupplierProvider';
import { Currency } from 'models/common';
import { ProductI } from 'models/product';
import { FC, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdImage } from 'react-icons/md';
import { getDetail } from 'utils/services/apis/product.api';
import CreateSupplier from './CreateSupplier';
import Properties from './Properties';
import Quantitation from './Quantitation';
import Select from 'react-select';
import { ProductGroupContext } from 'components/providers/ProductGroupProvider';
import { group } from 'console';

type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
  id?: string;
};

const CreateProductModal: FC<CreateProductModalProps> = ({ id, open, onClose }) => {
  const { data: suppliers } = useContext(SupplierContext);
  const { data: groups } = useContext(ProductGroupContext);
  const { create, update } = useContext(ProductContext);
  const toast = useToast();
  const { data: product } = useQuery<ProductI>({
    queryKey: ['products', id],
    queryFn: () => getDetail(id),
    retry: false,
    enabled: !!id,
  });

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, disabled },
    setValue,
    watch,
    reset,
  } = useForm<ProductI>({
    values: product,
    defaultValues: {
      noExpiry: true,
      sellingPrice: 1,
      originalPrice: 1,
    },
  });
  console.log(product);
  useEffect(() => {
    if (open && !id) {
      reset({ currency: Currency.VND });
    }
  }, [id, open, reset]);

  const onSubmit: SubmitHandler<ProductI> = async (data: ProductI) => {
    if (id) {
      // Update Product
      if (update) await update(id, { ...data, images: [] });
    } else {
      // Update Product
      if (create) {
        const res = await create({ ...data, images: [] });
        if (res) {
          toast({
            title: `Tạo thành công sản phẩm ${res.name}`,
            status: 'success',
          });
          onClose();
        }
      }
    }
  };
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const addImageHandler = () => {
    alert('not implement yet');
  };

  const selectedSupplier = suppliers.find((s) => s.id === watch('supplierId'));
  const selectedGroup = groups.find((g) => g.id === watch('productGroupId'));

  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent minW="60vw" minH="80vh">
        <ModalHeader>
          {id ? t('products.modal.updateProduct', { name: product?.name }) : t('products.modal.createProduct')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>{t('common.info')}</Tab>
              <Tab isDisabled>{t('common.description')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing={2}>
                  <FormControl isInvalid={!!errors.name?.type} display="flex">
                    <FormLabel marginY={2} w="12rem" fontWeight="bold">
                      {t('products.fields.name')}
                    </FormLabel>
                    <Box w="calc(100% - 12rem)">
                      <Input
                        {...register('name', {
                          required: true,
                        })}
                        variant="flushed"
                        placeholder={t('products.fields.name')}
                        color={textColor}
                      />
                      <FormErrorMessage color="red">
                        {errors.name?.type === 'required' &&
                          t('messages.validations.fieldRequired', { field: t('products.fields.name') })}
                      </FormErrorMessage>
                    </Box>
                  </FormControl>
                  <FormControl isInvalid={!!errors.name?.type} display="flex">
                    <FormLabel marginY={2} w="12rem" fontWeight="bold">
                      {t('products.fields.code')}
                    </FormLabel>
                    <Box w="calc(100% - 12rem)">
                      <Input
                        {...register('code', { required: true })}
                        variant="flushed"
                        placeholder={t('products.fields.code')}
                        color={textColor}
                      />
                      <FormErrorMessage color="red">
                        {errors.code?.type === 'required' &&
                          t('messages.validations.fieldRequired', { field: t('products.fields.code') })}
                      </FormErrorMessage>
                    </Box>
                  </FormControl>
                  <FormControl isInvalid={!!errors.description?.type} display="flex">
                    <FormLabel marginY={2} w="12rem" fontWeight="bold">
                      {t('common.description')}
                    </FormLabel>
                    <Textarea
                      variant="flushed"
                      w="calc(100% - 12rem)"
                      {...register('description', { maxLength: 2500 })}
                      placeholder={t('common.description')}
                      color={textColor}
                    />
                    <FormErrorMessage color="red">
                      {errors.description?.type === 'maxLength' && t('messages.validations.maxLength', { max: 2500 })}
                    </FormErrorMessage>
                  </FormControl>

                  <Flex gap={3}>
                    <FormControl isInvalid={!!errors.originalPrice?.type} display="flex">
                      <FormLabel marginY={2} w="12rem" fontWeight="bold">
                        {t('products.fields.originalPrice')}
                      </FormLabel>
                      <Box w="10rem">
                        <NumberInput allowMouseWheel min={0} max={1000000000}>
                          <NumberInputField
                            {...register('originalPrice', { min: 0, max: 1000000000, required: true })}
                            color={textColor}
                            placeholder={t('products.fields.originalPrice')}
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage color="red">
                          {errors.originalPrice?.type === 'required' &&
                            t('messages.validations.fieldRequired', { field: t('products.fields.originalPrice') })}
                        </FormErrorMessage>
                      </Box>
                    </FormControl>

                    <FormControl isInvalid={!!errors.sellingPrice?.type} display="flex" gap={5}>
                      <FormLabel marginY={2} w="4rem" fontWeight="bold">
                        {t('products.fields.sellingPrice')}
                      </FormLabel>
                      <Box w="10rem">
                        <NumberInput allowMouseWheel min={0} max={1000000000}>
                          <NumberInputField
                            {...register('sellingPrice', { min: 0, max: 1000000000, required: true })}
                            color={textColor}
                            placeholder={t('products.fields.sellingPrice')}
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage color="red">
                          {errors.sellingPrice?.type === 'required' &&
                            t('messages.validations.fieldRequired', { field: t('products.fields.sellingPrice') })}
                        </FormErrorMessage>
                      </Box>
                    </FormControl>
                    <FormControl isInvalid={!!errors.currency?.type} display="flex" gap={2}>
                      <FormLabel fontWeight="bold" marginY={2}>
                        {t('products.fields.currency')}
                      </FormLabel>
                      <Controller
                        name="currency"
                        defaultValue={Currency.VND}
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <RadioGroup {...rest} pt={2} display="flex" alignItems="start" gap={5} isDisabled>
                            <Radio value={Currency.VND}>VNĐ</Radio>
                            <Radio value={Currency.USD}>USD</Radio>
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Flex>

                  <FormControl isInvalid={!!errors.currency?.type} display="flex" gap={2} my={2}>
                    <Checkbox {...register('noExpiry')} />
                    <FormLabel marginY={2} w="20rem" fontWeight="bold">
                      {t('products.fields.noExpiry')}
                    </FormLabel>
                  </FormControl>

                  <FormControl display="flex">
                    <FormLabel marginY={2} w="12rem" fontWeight="bold">
                      {t('products.fields.group')}
                    </FormLabel>
                    <Box w="30rem">
                      <Controller
                        name="productGroupId"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            value={{ value: value, label: selectedGroup?.name }}
                            options={groups.filter((g) => g.level === 3).map((s) => ({ label: s.name, value: s.id }))}
                            onChange={({ value }) => onChange(value)}
                          />
                        )}
                      />
                    </Box>
                  </FormControl>
                  <FormControl display="flex">
                    <FormLabel
                      marginY={2}
                      w="12rem"
                      fontWeight="bold"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {t('products.fields.supplier')}
                      <CreateSupplier />
                    </FormLabel>
                    <Box w="30rem">
                      <Controller
                        name="supplierId"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            value={{ value: value, label: selectedSupplier?.name }}
                            options={suppliers.map((s) => ({ label: s.name, value: s.id }))}
                            onChange={({ value }) => onChange(value)}
                          />
                        )}
                      />
                    </Box>
                  </FormControl>

                  <Controller
                    name="quantitationUnits"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Quantitation
                        baseUnit={watch('baseUnit')}
                        onChangeBaseUnit={(unit) => setValue('baseUnit', unit)}
                        values={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name="properties"
                    control={control}
                    render={({ field: { value, onChange } }) => <Properties values={value} onChange={onChange} />}
                  />

                  <FormControl>
                    <FormLabel marginY={2} w="12rem" fontWeight="bold">
                      <Flex gap={3} alignItems="center">
                        {t('products.fields.images')}
                        <IconButton
                          aria-label="add-image"
                          icon={<MdImage />}
                          bgColor="ButtonShadow"
                          title={t('buttons.add')}
                          onClick={addImageHandler}
                        />
                      </Flex>
                    </FormLabel>
                    <Image src="shynh-warehouse.png" fallbackSrc="https://via.placeholder.com/150" />
                  </FormControl>
                </Stack>
              </TabPanel>
              <TabPanel>Not Implement Yet</TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit(onSubmit)} isDisabled={disabled}>
            {t('buttons.save')}
          </Button>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProductModal;
