import { Alert } from '@chakra-ui/react';
import { GlobalAppContext } from 'components/providers/AppProvider';
import WarehouseTransactionProvider from 'components/providers/WarehouseTransactionProvider';
import { TransactionType } from 'models/transaction';
import { FC, useContext, useState } from 'react';
import TransactionModal from './TransactionModal';
import TransactionTable from './TransactionTable';

const WarehouseTransaction: FC = () => {
  const [selectedTransactionId, setTransactionId] = useState<string | undefined>();
  const [creatingType, setCreatingType] = useState<TransactionType | undefined>();
  const { warehouse } = useContext(GlobalAppContext);

  const closeModalHandler = () => {
    setTransactionId(undefined);
    setCreatingType(undefined);
  };

  return warehouse ? (
    <WarehouseTransactionProvider warehouse={warehouse}>
      <TransactionModal
        open={!!selectedTransactionId || !!creatingType}
        onClose={closeModalHandler}
        type={creatingType}
        transactionId={selectedTransactionId}
      />
      <TransactionTable
        selectTransaction={setTransactionId}
        onSelectCreateTransaction={(type: TransactionType) => setCreatingType(type)}
      />
    </WarehouseTransactionProvider>
  ) : (
    <Alert>Vui lòng chọn Kho</Alert>
  );
};

export default WarehouseTransaction;
