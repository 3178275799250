import { useMutation, useQuery } from '@tanstack/react-query';
import { CrudContextType } from 'models/context-type';
import { CreateProductRequestI, ProductFilterQuery, ProductI } from 'models/product';
import { UpdateProductGroupRequestI } from 'models/product-group';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { DEFAULT_PRODUCT_FILTER } from 'utils/constants/default-value';
import { createProduct, deleteProduct, fetchProducts, updateProduct } from 'utils/services/apis/product.api';
import { GlobalAppContext } from './AppProvider';

type ProductFilterType = {
  filter: ProductFilterQuery;
  setFilter: (filter: ProductFilterQuery) => void;
};
type ProductContextType = CrudContextType<ProductI, CreateProductRequestI> & ProductFilterType;
export const ProductContext = createContext<ProductContextType>({} as ProductContextType);

const ProductProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const [filter, setFilter] = useState<ProductFilterQuery>(DEFAULT_PRODUCT_FILTER);
  const {
    data = [],
    refetch: fetchProductHandler,
    isFetching,
  } = useQuery<ProductI[]>({
    queryKey: ['products', filter],
    queryFn: () => fetchProducts(filter),
  });

  const { mutateAsync: createProductHandler, isPending: isCreatePending } = useMutation({
    mutationFn: async (group: CreateProductRequestI) => await createProduct(group),
    onSuccess: async () => await fetchProductHandler(),
  });

  const { mutateAsync: updateProductHandler, isPending: isUpdatePending } = useMutation({
    mutationFn: async (product: { id: string; data: UpdateProductGroupRequestI }) =>
      await updateProduct(product.id, product.data),
    onSuccess: async () => await fetchProductHandler(),
  });

  const { mutateAsync: deleteProductHandler, isPending: isDeletePending } = useMutation({
    mutationFn: async (id: string) => await deleteProduct(id),
    onSuccess: async () => await fetchProductHandler(),
  });

  const isLoading = isFetching || isCreatePending || isUpdatePending || isDeletePending;

  useEffect(() => {
    toggleLoading(isLoading, 'product');
  }, [isLoading]);

  return (
    <ProductContext.Provider
      value={{
        filter,
        data,
        setFilter,
        create: createProductHandler,
        update: async (id, data) => await updateProductHandler({ id, data }),
        delete: deleteProductHandler,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
