import axios from 'axios';
import { FilterQuery } from 'models/common';
import { CreateProductGroupRequestI, ProductGroupI } from 'models/product-group';
import { ENDPOINTS } from 'utils/constants/endpoint';

export const fetchProductGroups = async (filter: FilterQuery) =>
  await axios
    .get<ProductGroupI[]>(ENDPOINTS.productGroups, {
      params: filter,
    })
    .then((resp) => resp.data);

export const createProductGroup = async (product: CreateProductGroupRequestI) =>
  await axios.post<ProductGroupI>(ENDPOINTS.productGroups, product).then((resp) => resp.data);
