import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  Table,
  TableCaption,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { WarehouseTransactionContext } from 'components/providers/WarehouseTransactionProvider';
import dayjs from 'dayjs';
import { TransactionI, TransactionStatus, TransactionType } from 'models/transaction';
import { Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxOpen } from 'react-icons/fa6';
import { StatusTagColor, TransactionTypeColor } from 'utils/constants/color';
import TransactionMenu from './TransactionMenu';

const TransactionTable: FC<{
  selectTransaction: Dispatch<SetStateAction<string | undefined>>;
  onSelectCreateTransaction: (type: TransactionType) => void;
}> = ({ selectTransaction, onSelectCreateTransaction }) => {
  const { t } = useTranslation();
  const { data, warehouse, isLoading, filter, setFilter } = useContext(WarehouseTransactionContext);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = useMemo<ColumnDef<TransactionI>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: t('warehouse.fields.transCode'),
        cell: ({ getValue }) => <Text fontWeight={900}>{getValue<string>()}</Text>,
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: t('common.type'),
        cell: ({ getValue }) => (
          <Text fontSize="sm" fontWeight="700" color={TransactionTypeColor[getValue<TransactionType>()]}>
            {t(`warehouse.transaction.type.${getValue<TransactionType>().toLocaleLowerCase()}`)}
          </Text>
        ),
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: () => t('warehouse.fields.status'),
        cell: ({ getValue }) => (
          <Tag size="md" variant="solid" rounded={0} bgColor={StatusTagColor[getValue<TransactionStatus>()]}>
            {t(`warehouse.transaction.status.${getValue<TransactionStatus>().toLocaleLowerCase()}`)}
          </Tag>
        ),
      },
      {
        id: 'fromTo',
        accessorKey: 'type',
        header: t('common.activity'),
        cell: ({ getValue, row }) => {
          let activity = '';
          switch (getValue<TransactionType>()) {
            case TransactionType.IMPORT_PRIMARY_WAREHOUSE:
              activity = t('warehouse.transaction.importPrimaryWarehouse');
              break;
            case TransactionType.ADJUST_DEFECTIVE_GOODS:
            case TransactionType.CHECK_INVENTORY:
              activity = t('warehouse.transaction.activity');
              break;
            case TransactionType.EXPORT:
              activity = t('warehouse.transaction.exportTo', {
                warehouse: row.original.importWarehouse?.name,
              });
              break;
            case TransactionType.IMPORT:
              activity = t('warehouse.transaction.importFrom', {
                warehouse: row.original.exportWarehouse?.name,
              });
              break;
          }

          return activity;
        },
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: t('common.description'),
        cell: ({ getValue }) => getValue<string>(),
      },

      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: t('common.createdAt'),
        cell: ({ getValue }) => dayjs(getValue<string>()).format('DD/MM/YYYY hh:mm'),
      },
      {
        id: 'modifiedAt',
        accessorKey: 'modifiedAt',
        header: t('common.modifiedAt'),
        cell: ({ getValue }) => dayjs(getValue<string>()).format('DD/MM/YYYY hh:mm'),
      },
    ],
    [t]
  );

  const transactionTable = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    getRowId: (e) => e.id,
    enableRowSelection: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    // getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const transactionTypes = Object.values(TransactionType).filter((type) =>
    warehouse?.isPrimary
      ? type !== TransactionType.IMPORT
      : ![TransactionType.IMPORT_PRIMARY_WAREHOUSE, TransactionType.EXPORT].includes(type)
  );

  const transactionStatuses = [TransactionType.EXPORT, TransactionType.IMPORT].includes(filter.type)
    ? Object.values(TransactionStatus)
    : [TransactionStatus.DONE];

  return (
    <Card flexDirection="column">
      <Flex mb="8px" justifyContent="space-between" align="center">
        <Flex gap={2} alignItems="center">
          <Text color={textColor} fontSize="22px" mb="4px" fontWeight="700" lineHeight="100%">
            {t('warehouse.transaction.title')}
          </Text>
          <TransactionMenu onSelectTransactionType={onSelectCreateTransaction} />
        </Flex>

        <Flex gap={2}>
          <FormControl w="10rem">
            <FormLabel>Loại giao dịch</FormLabel>
            <Select
              value={filter.type || 'all'}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  type: e.target.value === 'all' ? undefined : (e.target.value as TransactionType),
                  status: undefined,
                });
              }}
            >
              <option value="all">{t('common.all')}</option>
              {transactionTypes.map((type) => (
                <option value={type} key={type}>
                  {t(`warehouse.transaction.type.${type.toLocaleLowerCase()}`)}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl w="10rem">
            <FormLabel>Trạng thái</FormLabel>
            <Select
              value={filter.status || 'all'}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  status: e.target.value === 'all' ? undefined : (e.target.value as TransactionStatus),
                })
              }
            >
              <option value="all">{t('common.all')}</option>
              {transactionStatuses.map((status) => (
                <option value={status} key={status}>
                  {t(`warehouse.transaction.status.${status.toLocaleLowerCase()}`)}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Flex>
      <TableContainer>
        <Table size="sm" mb="24px" mt="12px">
          {!data?.length && (
            <TableCaption>
              <Skeleton isLoaded={!isLoading}>
                <Flex justify="center" alignItems="center" gap={2}>
                  <FaBoxOpen /> {t('warehouse.text.noTransactions')}
                </Flex>
              </Skeleton>
            </TableCaption>
          )}
          <Thead bgColor="cyan.400" color="white">
            {transactionTable.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    borderColor={borderColor}
                    onClick={header.column.getToggleSortingHandler()}
                    justifyContent="space-between"
                    py={5}
                    color="inherit"
                    fontSize="0.875rem"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody minH="30vh">
            {transactionTable.getRowModel().rows.map((row, index) => (
              <Tr
                key={row.id}
                bgColor={index % 2 ? 'cyan.50' : 'white'}
                _hover={{ bgColor: 'cyan.200', cursor: 'pointer' }}
                onClick={() => selectTransaction(row.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor="transparent"
                  >
                    <Skeleton minH="10px" isLoaded={!isLoading}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Skeleton>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TransactionTable;
