import axios from 'axios';
import { FilterQuery } from 'models/common';
import { CreateWarehouseRequestI, UpdateWarehouseRequestI, WarehouseI } from 'models/warehouse';
import { ENDPOINTS } from 'utils/constants/endpoint';

export const fetchWarehouses = async (filter?: FilterQuery) =>
  await axios
    .get<WarehouseI[]>(ENDPOINTS.warehouse, {
      params: filter,
    })
    .then((resp) => resp.data);

export const createWarehouse = async (Warehouse: CreateWarehouseRequestI) =>
  await axios.post<WarehouseI>(ENDPOINTS.warehouse, Warehouse).then((resp) => resp.data);

export const updateWarehouse = async (id: string, warehouse: UpdateWarehouseRequestI) =>
  await axios.patch<WarehouseI>(`${ENDPOINTS.warehouse}/${id}`, warehouse).then((resp) => resp.data);

export const deleteWarehouse = async (id: string) =>
  await axios.delete<WarehouseI>(`${ENDPOINTS.warehouse}/${id}`).then((resp) => resp.data);
