export enum Currency {
  VND = 'VND',
  USD = 'USD',
}

export type AuditField = 'id' | 'createdAt' | 'modifiedAt' | 'active';

export type FilterQuery = {
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortAsc?: boolean;
};
