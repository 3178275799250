import { useMutation, useQuery } from '@tanstack/react-query';
import { FilterQuery } from 'models/common';
import { CrudContextType } from 'models/context-type';
import { CreateWarehouseRequestI, UpdateWarehouseRequestI, WarehouseI } from 'models/warehouse';
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { createWarehouse, fetchWarehouses, updateWarehouse } from 'utils/services/apis/warehouse.api';
import { GlobalAppContext } from './AppProvider';

export type WarehouseContextType = CrudContextType<WarehouseI, CreateWarehouseRequestI> & {
  filter: FilterQuery;
  setFilter: Dispatch<SetStateAction<FilterQuery>>;
};
export const WarehouseContext = createContext<WarehouseContextType>({} as WarehouseContextType);

const WarehouseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const [filter, setFilter] = useState<FilterQuery>({});
  const {
    data = [],
    refetch: refetchWarehouse,
    isFetching,
  } = useQuery<WarehouseI[]>({
    queryKey: ['warehouses'],
    queryFn: async () => await fetchWarehouses(filter),
  });

  const { mutateAsync: create, isPending: isCreateLoading } = useMutation({
    mutationFn: async (wh: CreateWarehouseRequestI) => await createWarehouse(wh),
    onSuccess: async () => await refetchWarehouse(),
  });

  const { mutateAsync: update, isPending: isUpdateLoading } = useMutation({
    mutationFn: async (warehouse: { id: string; data: UpdateWarehouseRequestI }) =>
      await updateWarehouse(warehouse.id, warehouse.data),
    onSuccess: async () => await refetchWarehouse(),
  });

  const loading = isFetching || isCreateLoading || isUpdateLoading;

  useEffect(() => {
    toggleLoading(loading, 'warehouse');
  }, [loading]);

  return (
    <WarehouseContext.Provider
      value={{
        data,
        update: async (id: string, data: UpdateWarehouseRequestI) => update({ id, data }),
        create,
        filter,
        setFilter,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};

export default WarehouseProvider;
