import { useToast } from '@chakra-ui/react';
import ShynhLoader from 'components/loader/shynh-loader';
import Auth from 'layouts/auth';
import { UserInfoI } from 'models/auth';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { getUserInfo, login, logout } from 'utils/services/apis/auth.api';

type AuthContextType = {
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  userInfo?: UserInfoI;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isFetching, toggleFetching] = useState(false);
  const [user, setUser] = useState<UserInfoI>();
  const toast = useToast();

  const fetchUserInfo = async () => {
    toggleFetching(true);
    const user = await getUserInfo();
    if (user) setUser(user);
    toggleFetching(false);
  };

  const loginAsync = async (username: string, password: string) => {
    if (!password || !username) {
      toast({
        title: 'Vui lòng nhập tài khoản và mặt khẩu',
        status: 'error',
      });
      return;
    }

    toggleFetching(true);
    const res = await login(username, password);
    toggleFetching(false);

    if (!res) {
      toast({
        title: 'Thông tin không chính xác, vui lòng nhập lại',
        status: 'error',
      });
    } else {
      await fetchUserInfo();
    }
  };

  const logoutAsync = async () => {
    toggleFetching(true);
    await logout();
    setUser(undefined);
    toggleFetching(false);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login: loginAsync,
        logout: logoutAsync,
        userInfo: user,
      }}
    >
      <ShynhLoader loading={isFetching} />
      {!isFetching && user ? children : <Auth />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
