import AuthProvider from 'components/providers/AuthProvider';
import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import AdminLayout from './layouts/admin';
// import RTLLayout from './layouts/rtl';

const getRoutes = (data: RouteType[]): ReactNode => {
  const defaultRoute = data.find((route) => route.default);
  console.log('routes', data);
  return (
    <>
      {data.map((route) => (
        <Route path={route.path} element={route.component} key={route.path}>
          {Boolean(route.children?.length) && getRoutes(route.children)}
        </Route>
      ))}
      {defaultRoute !== undefined && <Route index path="*" element={<Navigate to={defaultRoute.path} replace />} />}
    </>
  );
};
const Application = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AdminLayout />}>{getRoutes(routes.filter((r) => r.layout === 'admin'))}</Route>
      </Routes>
    </AuthProvider>
  );
};

export default Application;
