import { CalendarIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement, useColorModeValue } from '@chakra-ui/react';
import { forwardRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker.css';

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <>
    <Input autoComplete="off" background="white" value={value} onChange={onChange} ref={ref} onClick={onClick} />
    <InputRightElement
      color="gray.500"
      children={<CalendarIcon fontSize="sm" cursor="pointer" />}
      ref={ref}
      onClick={onClick}
    />
  </>
);

const CustomInput = forwardRef(customDateInput);

const DatePicker = ({ ...props }: ReactDatePickerProps) => {
  const inputClassName = useColorModeValue('light-theme', 'dark-theme');

  return (
    <InputGroup className={inputClassName}>
      <ReactDatePicker className="react-datapicker__input-text" customInput={<CustomInput />} {...props} />
    </InputGroup>
  );
};

export default DatePicker;
