import { DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ProductQuantitationUnitI } from 'models/product';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Quantitation: FC<{
  baseUnit?: string;
  onChangeBaseUnit: (unitName: string) => void;
  values?: ProductQuantitationUnitI[];
  onChange: (values: ProductQuantitationUnitI[]) => void;
}> = ({ baseUnit, values = [], onChange, onChangeBaseUnit }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();

  const clickAddHandler = () => onChange([...values, { sellingPrice: 0, totalInHigherUnit: 1, unitName: '' }]);

  const changePropertyHandler = (index: number, field: keyof ProductQuantitationUnitI, value: string | number) => {
    values[index][field] = value as never;
    onChange([...values]);
  };

  const deletePropertyHandler = (index: number) => onChange(values.filter((v, idx) => idx !== index));

  const checkDuplicate = (index: number, name: string) =>
    values.some((v, idx) => index !== idx && v.unitName.trim() === name.trim());
  console.log(values);
  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem border="1px" borderColor="gray.200">
        <h2>
          <AccordionButton bgColor="gainsboro">
            <Box as="span" flex="1" textAlign="left">
              {t('products.fields.quantitation')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <FormControl display="flex">
            <FormLabel marginY={2} w="12rem" fontWeight="bold">
              {t('products.fields.unitName')}
            </FormLabel>
            <Input value={baseUnit} onChange={(e) => onChangeBaseUnit(e.target.value)} />
          </FormControl>
          {baseUnit && !!values.length && (
            <Flex direction="column" alignItems="flex-start" gap={1} mt="1rem">
              <Flex gap={4}>
                <Text w="10rem" fontWeight={700}>
                  {t('products.fields.unitName')} con
                </Text>
                <Text w="10rem" fontWeight={700}>
                  {t('products.fields.sellingPrice')}
                </Text>
              </Flex>

              {values.map(({ unitName, sellingPrice, totalInHigherUnit }, index) => (
                <Flex gap={4} key={index}>
                  <FormControl isInvalid={checkDuplicate(index, unitName) || !unitName} w="10rem">
                    <Input
                      placeholder={t('products.fields.unitName')}
                      value={unitName}
                      onChange={(e) => changePropertyHandler(index, 'unitName', e.target.value)}
                      color={textColor}
                    />
                    <FormErrorMessage color="red">
                      {!unitName && t('messages.validations.fieldRequired', { field: t('products.fields.unitName') })}
                      {checkDuplicate(index, unitName) &&
                        t('messages.validations.unique', { field: t('products.fields.unitName') })}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!sellingPrice} w="10rem">
                    <NumberInput
                      allowMouseWheel
                      min={0}
                      max={1000000000}
                      value={sellingPrice}
                      onChange={(e) => changePropertyHandler(index, 'sellingPrice', Number(e))}
                      color={textColor}
                    >
                      <NumberInputField placeholder={t('products.fields.sellingPrice')} color={textColor} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage color="red">
                      {!unitName &&
                        t('messages.validations.fieldRequired', { field: t('products.fields.sellingPrice') })}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl w="22rem" isInvalid={!totalInHigherUnit}>
                    <InputGroup>
                      <InputLeftElement width="12rem" justifyContent="start" gap={1}>
                        SL <strong>{unitName}</strong> trong 1<strong>{values[index - 1]?.unitName || baseUnit}</strong>
                      </InputLeftElement>
                      <NumberInput
                        pl="12rem"
                        w="100%"
                        allowMouseWheel
                        min={1}
                        max={1000}
                        value={totalInHigherUnit}
                        onChange={(e) => changePropertyHandler(index, 'totalInHigherUnit', Number(e))}
                        color={textColor}
                      >
                        <NumberInputField color={textColor} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </InputGroup>
                    <FormErrorMessage color="red">
                      {!sellingPrice &&
                        t('messages.validations.fieldRequired', { field: t('products.fields.totalInHigherUnit') })}
                    </FormErrorMessage>
                  </FormControl>
                  <IconButton
                    aria-label="delete-unit"
                    bgColor="red"
                    color="white"
                    icon={<DeleteIcon />}
                    onClick={() => deletePropertyHandler(index)}
                  />
                </Flex>
              ))}
              <Button
                rounded="none"
                colorScheme="green"
                size="md"
                width="200px"
                onClick={clickAddHandler}
                leftIcon={<PlusSquareIcon />}
              >
                {t('buttons.add')} {t('products.fields.childUnit').toLowerCase()}
              </Button>
            </Flex>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Quantitation;
