import axios from 'axios';
import { FilterQuery } from 'models/common';
import { CreateSupplierRequestI, SupplierI } from 'models/supplier';
import { ENDPOINTS } from 'utils/constants/endpoint';

const SUPPLIER_ENDPOINT = `${process.env.REACT_APP_ENDPOINT}${ENDPOINTS.suppliers}`;

export const fetchSuppliers = async (filter: FilterQuery) =>
  await axios
    .get<SupplierI[]>(SUPPLIER_ENDPOINT, {
      params: filter,
    })
    .then((resp) => resp.data);

export const createSupplier = async (supplier: CreateSupplierRequestI) =>
  await axios.post<SupplierI>(SUPPLIER_ENDPOINT, supplier).then((resp) => resp.data);
