import { useMutation, useQuery } from '@tanstack/react-query';
import { TransactionFilterI, TransactionI } from 'models/transaction';
import { WarehouseI } from 'models/warehouse';
import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { createTransaction, fetchWarehouseTransactions, updateTransaction } from 'utils/services/apis/transaction.api';
import { GlobalAppContext } from './AppProvider';

export type WarehouseTransactionContextType = {
  isLoading: boolean;
  data: TransactionI[];
  filter: TransactionFilterI;
  update: (transaction: TransactionI) => Promise<TransactionI>;
  create: (transaction: TransactionI) => Promise<TransactionI>;
  setFilter: (filter: TransactionFilterI) => void;
  warehouse?: WarehouseI;
};
export const WarehouseTransactionContext = createContext<WarehouseTransactionContextType>(
  {} as WarehouseTransactionContextType
);

const WarehouseTransactionProvider: FC<{ children: ReactNode; warehouse?: WarehouseI }> = ({ children, warehouse }) => {
  const { toggleLoading } = useContext(GlobalAppContext);
  const [filter, setFilter] = useState<TransactionFilterI>({});
  const {
    data = [],
    refetch: fetchGroupsHandler,
    isFetching,
  } = useQuery<TransactionI[]>({
    queryKey: ['warehouseTransactions', warehouse?.id, Object.values(filter)],
    queryFn: async () => await fetchWarehouseTransactions(warehouse?.id, filter),
    enabled: !!warehouse,
  });

  const { mutateAsync: update, isPending: isUpdatePending } = useMutation({
    mutationFn: async (transaction: TransactionI) => await updateTransaction(warehouse?.id, transaction),
    onSuccess: async () => await fetchGroupsHandler(),
  });

  const { mutateAsync: create, isPending: isCreatePending } = useMutation({
    mutationFn: async (transaction: TransactionI) => await createTransaction(transaction),
    onSuccess: async () => await fetchGroupsHandler(),
  });

  const loading = isFetching || isUpdatePending || isCreatePending;

  useEffect(() => {
    toggleLoading(loading, 'transaction');
  }, [loading]);

  return (
    <WarehouseTransactionContext.Provider
      value={{
        isLoading: isFetching || isUpdatePending || isCreatePending,
        data,
        update,
        create,
        filter,
        setFilter,
        warehouse,
      }}
    >
      {children}
    </WarehouseTransactionContext.Provider>
  );
};

export default WarehouseTransactionProvider;
