import ShynhLoader from 'components/loader/shynh-loader';
import { WarehouseI } from 'models/warehouse';
import { FC, ReactNode, createContext, useState } from 'react';

export type GlobalAppContextType = {
  warehouse?: WarehouseI;
  setWarehouse: (warehouse: WarehouseI) => void;
  toggleLoading: (loading: boolean, module: keyof ModuleLoaders) => void;
};
export const GlobalAppContext = createContext<GlobalAppContextType>({} as GlobalAppContextType);

type ModuleLoaders = {
  productGroup: boolean;
  product: boolean;
  shynhAdmin: boolean;
  supplier: boolean;
  warehouse: boolean;
  inventory: boolean;
  transaction: boolean;
};

const GlobalAppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loaders, setLoaders] = useState<ModuleLoaders>({
    inventory: false,
    product: false,
    productGroup: false,
    shynhAdmin: false,
    supplier: false,
    transaction: false,
    warehouse: false,
  });

  const toggleLoading = (loading: boolean, module: keyof ModuleLoaders) =>
    setLoaders({
      ...loaders,
      [module]: loading,
    });

  const [warehouse, setWarehouse] = useState<WarehouseI>();

  return (
    <GlobalAppContext.Provider
      value={{
        warehouse,
        setWarehouse,
        toggleLoading,
      }}
    >
      <ShynhLoader loading={Object.values(loaders).some((loading) => loading)} />
      {children}
    </GlobalAppContext.Provider>
  );
};

export default GlobalAppProvider;
