import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/App.css';
import './assets/i18n';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Application from 'app';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Trans } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme/theme';
import 'utils/services/apis/axios';

dayjs.extend(localizedFormat);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Trans>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Application />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </Trans>
    </React.StrictMode>
  </ChakraProvider>
);
