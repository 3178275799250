import axios from 'axios';

const DEBUG = process.env.NODE_ENV === 'development';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
axios.defaults.withCredentials = true;
// axios.defaults.proxy= {

// };

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    if (DEBUG) {
      console.error('✉️ ', error);
    }
    return Promise.reject(error);
  }
);
