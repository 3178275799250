import { useQuery } from '@tanstack/react-query';
import { BranchI } from 'models/branch';
import { FC, ReactNode, createContext, useContext, useEffect } from 'react';
import { fetchBranches } from 'utils/services/apis/shynhadmin.api';
import { GlobalAppContext } from './AppProvider';

export type ShynhAdminContextType = {
  branches: BranchI[];
};
export const ShynhAdminContext = createContext<ShynhAdminContextType>({} as ShynhAdminContextType);

const ShynhAdminProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { data: branches = [], isFetching } = useQuery<BranchI[]>({
    queryKey: ['branches'],
    queryFn: async () => await fetchBranches(),
  });

  const { toggleLoading } = useContext(GlobalAppContext);

  useEffect(() => {
    toggleLoading(isFetching, 'shynhAdmin');
  }, [isFetching]);

  return (
    <ShynhAdminContext.Provider
      value={{
        branches,
      }}
    >
      {children}
    </ShynhAdminContext.Provider>
  );
};

export default ShynhAdminProvider;
