import { DeleteIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/helper.ts/common';

const ProductInfo: FC<{ product: SearchProductI; onDeleteProduct?: () => void }> = ({ product, onDeleteProduct }) => {
  const { t } = useTranslation();
  const unitOptions = [product.baseUnit, ...product.quantitationUnits.map((x) => x.unitName)];
  const stock = useMemo(
    () =>
      product.inventories
        .reduce((arr: InventoryQuantityI[], inventory) => {
          const idx = arr.findIndex((item) => item.quantitationUnit === inventory.quantitationUnit);
          if (idx !== -1) arr[idx].quantity += inventory.quantity;
          else arr.push({ ...inventory });
          return arr;
        }, [])
        .map(({ quantitationUnit, quantity }) => `${quantity} ${quantitationUnit}`)
        .join(', '),
    [product.inventories]
  );

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex gap={2} alignItems="center">
        <Image src={product.image} w="3rem" fallbackSrc="https://via.placeholder.com/150" />
        <Flex direction="column" justifyContent="space-between">
          <Text noOfLines={1}>{product.name}</Text>
          <Flex gap={1}>
            <Tag colorScheme={product.inventories.length ? 'purple' : 'red'}>
              <TagLabel>
                {t('warehouse.fields.stock')}: {stock || 0}
              </TagLabel>
            </Tag>
            {product.noExpiry && (
              <Tooltip label="Sản phẩm này không cần Hạn sử dụng" hasArrow bgColor="orange.400" placement="top">
                <Tag colorScheme="orange">Không HSD</Tag>
              </Tooltip>
            )}
            {unitOptions.length > 1 && (
              <Popover trigger="hover">
                <PopoverTrigger>
                  <Tag bgColor="orange.400" color="white" _hover={{ cursor: 'pointer' }}>
                    Thông tin định lượng
                  </Tag>
                </PopoverTrigger>
                <PopoverContent w="30rem" p={2}>
                  <PopoverArrow />
                  <PopoverBody>
                    <Stack spacing={1}>
                      {product.quantitationUnits.map((unit, index) => (
                        <Flex justifyContent="space-between" key={unit.unitName}>
                          <Text width="60%" gap={1}>
                            <b>{'1 ' + (product.quantitationUnits[index - 1]?.unitName || product.baseUnit)}</b>
                            {' chứa '}
                            <b>
                              {unit.totalInHigherUnit} {unit.unitName}
                            </b>
                          </Text>
                          <Text w="40%">
                            Giá bán <b>{formatCurrency(unit.sellingPrice)} VNĐ</b>
                          </Text>
                        </Flex>
                      ))}
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Flex>
        </Flex>
      </Flex>
      {!!onDeleteProduct && (
        <IconButton
          tabIndex={-1}
          colorScheme="red"
          aria-label="delete-item"
          icon={<DeleteIcon />}
          onClick={onDeleteProduct}
        />
      )}
    </Flex>
  );
};
export default ProductInfo;
