import { Box, Tab, TabList, Tabs, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const WarehouseRoutes = ['inventories', 'transactions'];

const WarehousePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentTabIndex = WarehouseRoutes.findIndex((r) => pathname.includes(r));
  const tabBgColor = useColorModeValue('white', 'inherit');

  return (
    <>
      <Tabs mt="8rem" index={currentTabIndex} onChange={(idx) => navigate(`/warehouse/${WarehouseRoutes[idx]}`)}>
        <TabList gap={1}>
          <Tab bgColor={tabBgColor}>{t('warehouse.tabs.inventory')}</Tab>
          <Tab bgColor={tabBgColor}>{t('warehouse.tabs.transaction')}</Tab>
        </TabList>
      </Tabs>
      <Box bgColor="white" py={2}>
        <Outlet />
      </Box>
    </>
  );
};

export default WarehousePage;
