export const ENDPOINTS = {
  products: '/products',
  productGroups: '/product-groups',
  suppliers: '/suppliers',
  warehouse: '/warehouse',
  inventories: '/inventories',
  transactions: '/transactions',
  sa: '/shynh-admin',
  auth: '/auth',
};
