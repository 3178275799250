import { IconButton, useDisclosure } from '@chakra-ui/react';
import CreateSupplierModal from 'components/supplier/CreateSupplierModal';
import { FC } from 'react';
import { FaPlus } from 'react-icons/fa';

const CreateSupplier: FC = () => {
  const { onClose, isOpen, onOpen } = useDisclosure();

  return (
    <>
      <CreateSupplierModal isOpen={isOpen} onClose={onClose} />
      <IconButton
        onClick={onOpen}
        aria-label="add supplier"
        icon={<FaPlus />}
        title="Thêm nhà cung cấp mới"
        ml={2}
        size="sm"
        colorScheme="green"
      />
    </>
  );
};

export default CreateSupplier;
