import axios from 'axios';
import { TransactionFilterI, TransactionI, TransactionType } from 'models/transaction';
import { ENDPOINTS } from 'utils/constants/endpoint';

export const fetchWarehouseTransactions = async (warehouseId: string, filter?: TransactionFilterI) =>
  await axios
    .get<TransactionI[]>(`${ENDPOINTS.transactions}/${warehouseId}`, {
      params: filter,
    })
    .then((resp) => resp.data);

export const getTransactionDetail = async (warehouseId: string, id: string) =>
  await axios.get<TransactionI>(`${ENDPOINTS.transactions}/${warehouseId}/${id}`).then((resp) => resp.data);

export const createTransaction = async ({ type, productTransactions, ...rest }: TransactionI) =>
  await axios
    .post<TransactionI>(ENDPOINTS.transactions, {
      ...rest,
      type,
      productTransactions: productTransactions.map((item) => ({
        ...item,
        quantity: type === TransactionType.IMPORT ? item.requestQuantity : item.actualQuantity,
      })),
    })
    .then((resp) => resp.data);

export const updateTransaction = async (warehouseId: string, transaction: TransactionI) =>
  await axios
    .put<TransactionI>(`${ENDPOINTS.transactions}/${warehouseId}/${transaction.id}`, transaction)
    .then((resp) => resp.data);
