import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { WarehouseProductI } from 'models/warehouse';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryDetail from './InventoryDetail';
import InventoryHistory from './InventoryHistory';

const InventoriesModal: FC<{ product?: WarehouseProductI; onClose: () => void }> = ({ product, onClose }) => {
  const { t } = useTranslation();
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  return (
    <Modal closeOnOverlayClick={false} isOpen={!!product} onClose={onClose} scrollBehavior="outside" isCentered>
      <ModalOverlay />
      <ModalContent minW="60vw" minH="80vh">
        <ModalHeader fontSize="x-large" noOfLines={1}>
          {t('products.name')}: <b>{product?.name}</b>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs variant="enclosed" index={currentTabIdx} onChange={(idx) => setCurrentTabIdx(idx)}>
            <TabList>
              <Tab>{t('warehouse.fields.quantity')}</Tab>
              <Tab>{t('warehouse.fields.histories')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>
                <InventoryDetail product={product} />
              </TabPanel>
              <TabPanel px={0}>
                <InventoryHistory product={product} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="flex-end">
          <Button onClick={onClose} minW="7rem">
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InventoriesModal;
