import { TransactionStatus, TransactionType } from 'models/transaction';

export const StatusTagColor: Record<TransactionStatus, string> = {
  [TransactionStatus.NOT_PROCESS]: 'gray.400',
  [TransactionStatus.PREPARE]: 'yellow.400',
  [TransactionStatus.EXPORTED]: 'blue.500',
  [TransactionStatus.IMPORTED]: 'teal.500',
  [TransactionStatus.RETURN]: 'purple.500',
  [TransactionStatus.DONE]: 'green.500',
  [TransactionStatus.CANCEL]: 'red.500',
};

export const TransactionTypeColor: Record<TransactionType, string> = {
  [TransactionType.IMPORT_PRIMARY_WAREHOUSE]: 'cyan.800',
  [TransactionType.IMPORT]: 'blue.700',
  [TransactionType.EXPORT]: 'yellow.700',
  [TransactionType.ADJUST_DEFECTIVE_GOODS]: 'red.400',
  [TransactionType.CHECK_INVENTORY]: 'green.500',
};
