import { Select, useColorModeValue } from '@chakra-ui/react';
import { GlobalAppContext } from 'components/providers/AppProvider';
import { WarehouseContext } from 'components/providers/WarehouseProvider';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const WarehouseSelect: FC = () => {
  const { t } = useTranslation();
  const { data: warehouses } = useContext(WarehouseContext);
  const { warehouse, setWarehouse } = useContext(GlobalAppContext);
  const textColor = useColorModeValue('black', 'white');

  useEffect(() => {
    if (warehouses?.length) {
      setWarehouse(warehouses[0]);
    }
  }, [warehouses]);

  return (
    <Select
      width="100%"
      value={warehouse?.id}
      onChange={(e) => setWarehouse(warehouses.find((wh) => wh.id === e.target.value))}
    >
      <option value={undefined} style={{ color: textColor }}>
        {t('common.na')}
      </option>
      {warehouses?.map((wh) => (
        <option value={wh.id} key={wh.id} style={{ color: textColor }}>
          {wh.name}
        </option>
      ))}
    </Select>
  );
};

export default WarehouseSelect;
