import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ShynhAdminContext } from 'components/providers/ShynhAdminProvider';
import { WarehouseContext } from 'components/providers/WarehouseProvider';
import { CreateWarehouseRequestI } from 'models/warehouse';
import { FC, useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CreateWarehouseModal: FC<{ id?: string; open: boolean; onClose: () => void }> = ({ open, id, onClose }) => {
  const { t } = useTranslation();
  const { data: warehouses, update, create } = useContext(WarehouseContext);
  const { branches } = useContext(ShynhAdminContext);
  const warehouse = warehouses?.find((wh) => wh.id === id) ?? ({} as CreateWarehouseRequestI);

  const {
    register,
    handleSubmit,
    formState: { errors, disabled },
    setValue,
    reset,
  } = useForm<CreateWarehouseRequestI>({ values: warehouse });

  useEffect(() => {
    if (open && !id) {
      reset({});
    }
  }, [open, reset, id]);

  const onSubmit: SubmitHandler<CreateWarehouseRequestI> = async (data: CreateWarehouseRequestI) => {
    if (id) {
      if (update) await update(id, data);
    } else {
      create && (await create(data));
    }
    onClose();
  };
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fields: (keyof CreateWarehouseRequestI)[] = ['name', 'description', 'address'];

  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose} scrollBehavior="inside" isCentered size="xl">
      <ModalOverlay />
      <ModalContent minW="50vw">
        <ModalHeader>{id ? t('warehouse.modal.update') : t('warehouse.modal.create')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={4}>
            <FormControl isInvalid={!!errors.branch?.type}>
              <InputGroup size="md">
                <InputLeftAddon fontWeight="bold" width="20%">
                  {t('warehouse.fields.branch')}
                </InputLeftAddon>
                <Select
                  {...register('branch', {
                    required: true,
                    onChange: (e) => {
                      const branch = branches.find((br) => br.name === e.target.value);
                      if (branch) {
                        setValue('name', `${t('warehouse.name')} ${branch.name}`);
                        setValue('address', branch.address);
                      }
                    },
                  })}
                >
                  <option value={undefined}>{t('common.na')}</option>
                  {branches
                    ?.filter((br) => warehouses?.every((wh) => wh.branch !== br.name))
                    .map((br) => (
                      <option value={br.name} key={br.id}>
                        {br.name}
                      </option>
                    ))}
                </Select>
              </InputGroup>
              <FormErrorMessage color="red">
                {errors.branch?.type === 'required' &&
                  t('messages.validations.fieldRequired', { field: t('products.fields.branch') })}
              </FormErrorMessage>
            </FormControl>
            {}
            {fields.map((field) => (
              <FormControl isInvalid={!!errors[field]?.type} key={field}>
                <InputGroup size="md">
                  <InputLeftAddon fontWeight="bold" width="20%">
                    {t(`warehouse.fields.${field}`)}
                  </InputLeftAddon>
                  <Input
                    {...register(field, {
                      required: field === 'name',
                    })}
                    placeholder={t(`warehouse.fields.${field}`)}
                    color={textColor}
                  />
                </InputGroup>
                <FormErrorMessage color="red">
                  {errors[field]?.type === 'required' &&
                    t('messages.validations.fieldRequired', { field: t(`products.fields.${field}`) })}
                </FormErrorMessage>
              </FormControl>
            ))}
            <Checkbox {...register('isPrimary')}>Kho tổng</Checkbox>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit(onSubmit)} isDisabled={disabled}>
            {t('buttons.save')}
          </Button>
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateWarehouseModal;
