import { AddIcon } from '@chakra-ui/icons';
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { WarehouseTransactionContext } from 'components/providers/WarehouseTransactionProvider';
import { TransactionType } from 'models/transaction';
import { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTruckLoading } from 'react-icons/fa';
import { FaBoxesStacked, FaParachuteBox } from 'react-icons/fa6';
import { GiBrokenPottery } from 'react-icons/gi';
import { GoChecklist } from 'react-icons/go';

const TransactionIcons: Record<TransactionType, ReactNode> = {
  [TransactionType.IMPORT]: <FaBoxesStacked />,
  [TransactionType.EXPORT]: <FaTruckLoading />,
  [TransactionType.ADJUST_DEFECTIVE_GOODS]: <GiBrokenPottery />,
  [TransactionType.CHECK_INVENTORY]: <GoChecklist />,
  [TransactionType.IMPORT_PRIMARY_WAREHOUSE]: <FaParachuteBox />,
};

const TransactionMenu: FC<{ onSelectTransactionType: (type: TransactionType) => void }> = ({
  onSelectTransactionType,
}) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'green.600' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'green.800' }, { bg: 'whiteAlpha.100' });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { warehouse } = useContext(WarehouseTransactionContext);

  const menuItems = Object.values(TransactionType).filter((type) =>
    warehouse?.isPrimary
      ? type !== TransactionType.IMPORT
      : ![TransactionType.IMPORT_PRIMARY_WAREHOUSE, TransactionType.EXPORT].includes(type)
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose} placement="right-start">
      <MenuButton
        alignItems="center"
        justifyContent="center"
        as={IconButton}
        icon={<AddIcon />}
        title="Tạo vận đơn"
        size="sm"
        borderRadius="50%"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        onClick={onOpen}
        bgColor="green.400"
        color="white"
      />
      <MenuList
        border="transparent"
        bg={bgList}
        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        borderRadius="20px"
        p="15px"
      >
        {menuItems.map((type) => (
          <MenuItem
            key={type}
            transition="0.2s linear"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            bgColor="transparent"
            mb="10px"
            onClick={() => onSelectTransactionType(type)}
          >
            <Flex align="center" py="0.5rem">
              <Icon h="30px" w="30px" me="8px">
                {TransactionIcons[type]}
              </Icon>
              <Text fontSize="lg" fontWeight="400">
                {t(`warehouse.transaction.menu.${type.toLocaleLowerCase()}`)}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default TransactionMenu;
