import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { AuditField } from 'models/common';
import { TransactionI, TransactionItemI, TransactionStatus } from 'models/transaction';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { FC, useMemo } from 'react';
import { Control, Controller, FieldArrayWithId, UseFormRegister, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { MAX_INPUT_PRODUCTS } from 'utils/constants/transaction';
import { formatCurrency } from 'utils/helper.ts/common';
import ProductInfo from './ProductInfo';

type TransactionItemWithId = FieldArrayWithId<Omit<TransactionI, AuditField>, 'productTransactions', 'formItemId'> & {
  index: number;
};

type ImportProductCardProps = {
  product: SearchProductI;
  onAddItem: (item: TransactionItemI) => void;
  onDeleteItem: (index: number) => void;
  onDeleteProduct: () => void;
  status?: TransactionStatus;
};

const ImportProductCard: FC<ImportProductCardProps> = ({
  onAddItem,
  onDeleteItem,
  onDeleteProduct,
  product,
  status,
}) => {
  const { register, control } = useFormContext();
  const transactionProducts: TransactionItemWithId[] = useWatch({
    name: 'productTransactions',
    control,
  });
  const transactionItems = transactionProducts
    .map((field, index) => ({ ...field, index }))
    .filter((item) => item.productId === product.id);

  const unitOptions = [product.baseUnit, ...product.quantitationUnits.map((u) => u.unitName)];

  const remainUnits = unitOptions.filter(
    (unit) => !transactionItems.map((item) => item.quantitationUnit).includes(unit)
  );

  const handleAddUnitForImport = () => {
    console.log('remainUnits', remainUnits);
    onAddItem({
      productId: product.id,
      quantitationUnit: remainUnits[0],
      requestQuantity: 1,
      inventoryQuantity: product.inventories.reduce((stock: number, item) => {
        if (item.quantitationUnit === remainUnits[0]) {
          return stock + item.quantity;
        } else return stock;
      }, 0),
    });
  };

  const showActualQuantityInfo = ![undefined, TransactionStatus.NOT_PROCESS].includes(status);
  const showRequestQuantityInfo = Boolean(status) && transactionItems.some((item) => Boolean(item.requestQuantity));
  const showReturnQuantityInfo = [TransactionStatus.DONE, TransactionStatus.RETURN].includes(status);

  return (
    <Card direction="column" variant="outline" key={product.id}>
      <CardBody>
        <Stack>
          <ProductInfo product={product} onDeleteProduct={!status ? onDeleteProduct : undefined} />
          {transactionItems.map((item) => (
            <Flex key={item.formItemId} justifyContent="flex-start" alignItems="center" gap={2}>
              {(!status || status === TransactionStatus.IMPORTED) && (
                <Flex alignItems="center" justifyContent="space-between" gap={1}>
                  {!status && (
                    <>
                      <Text fontWeight={700} w="7rem">
                        SL Yêu cầu
                      </Text>
                      <NumberInput
                        w="10rem"
                        min={1}
                        max={
                          product.quantitationUnits.find((u) => u.unitName === item.quantitationUnit)
                            ?.totalInHigherUnit || MAX_INPUT_PRODUCTS
                        }
                        allowMouseWheel
                      >
                        <NumberInputField
                          {...register(`productTransactions.${item.index}.requestQuantity`, {
                            min: 1,
                            max:
                              product.quantitationUnits.find((u) => u.unitName === item.quantitationUnit)
                                ?.totalInHigherUnit || MAX_INPUT_PRODUCTS,
                            required: true,
                            valueAsNumber: true,
                          })}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </>
                  )}

                  {status === TransactionStatus.IMPORTED && (
                    <>
                      <Text fontWeight={700} w="7rem">
                        SL Trả lại
                      </Text>
                      <NumberInput w="10rem" min={0} max={item.actualQuantity} allowMouseWheel>
                        <NumberInputField
                          {...register(`productTransactions.${item.index}.returnQuantity`, {
                            min: 0,
                            max: item.actualQuantity,
                            valueAsNumber: true,
                          })}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </>
                  )}
                </Flex>
              )}

              {showActualQuantityInfo && (
                <Flex alignItems="center">
                  <Text w="6rem">{status === TransactionStatus.PREPARE ? 'Dự kiến' : 'Đã chuyển'}:</Text>
                  <Text fontWeight="700" w="5rem">
                    {item.actualQuantity} {item.quantitationUnit}
                  </Text>
                  <Tag colorScheme={product.noExpiry ? undefined : 'cyan'} w="8rem">
                    {!product.noExpiry && `HSD ${dayjs(item.expiry).format('DD/MM/YYYY')}`}
                  </Tag>
                </Flex>
              )}

              {showRequestQuantityInfo && (
                <Tag
                  colorScheme={Boolean(item.requestQuantity) ? 'gray' : ''}
                  minW="8rem"
                  fontWeight={700}
                  variant="outline"
                >
                  {Boolean(item.requestQuantity) && `Yêu cầu ${item.requestQuantity} ${item.quantitationUnit}`}
                </Tag>
              )}

              {showReturnQuantityInfo && Boolean(item.returnQuantity) && (
                <Tag colorScheme="purple" minW="7rem" fontWeight={700}>
                  Trả lại {item.returnQuantity || 0} {item.quantitationUnit}
                </Tag>
              )}

              {!status && (
                <Box width="13rem">
                  {product.quantitationUnits.length ? (
                    <Controller
                      name={`productTransactions.${item.index}.quantitationUnit`}
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <Select
                          {...rest}
                          value={{ value, label: value }}
                          onChange={(e) => onChange(e.value)}
                          options={remainUnits.map((unit) => ({
                            value: unit,
                            label: unit,
                          }))}
                        />
                      )}
                    />
                  ) : (
                    product.baseUnit
                  )}
                </Box>
              )}

              {!status && transactionItems.length > 1 && (
                <Box w="2rem">
                  <IconButton
                    colorScheme="orange"
                    color="white"
                    aria-label="delete-trans-item"
                    icon={<DeleteIcon />}
                    onClick={() => onDeleteItem(item.index)}
                  />
                </Box>
              )}
            </Flex>
          ))}
          {!status && Boolean(remainUnits.length) && (
            <Button w="10rem" colorScheme="cyan" color="white" aria-label="new-unit" onClick={handleAddUnitForImport}>
              Thêm đơn vị khác
            </Button>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ImportProductCard;
