import axios from 'axios';
import { UserInfoI } from 'models/auth';
import { ENDPOINTS } from 'utils/constants/endpoint';

export const login = async (username: string, password: string) =>
  await axios
    .post(`${ENDPOINTS.auth}/sign-in`, { username, password })
    .then((resp) => resp.data)
    .catch(() => undefined);

export const logout = async () =>
  await axios
    .get(`${ENDPOINTS.auth}/sign-out`)
    .then((resp) => resp.data)
    .catch(() => undefined);

export const getUserInfo = async () =>
  await axios
    .get<UserInfoI>(`${ENDPOINTS.auth}/user-info`)
    .then((resp) => resp.data)
    .catch(() => undefined);
