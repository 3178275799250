import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import ShynhAdminProvider from 'components/providers/ShynhAdminProvider';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import WarehouseModal from 'components/warehouse-modal/CreateModal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WarehouseSelect from 'components/warehouse-modal/WarehouseSelect';
import WarehouseProvider from 'components/providers/WarehouseProvider';

const SidebarContent: FC<{ routes: MenuRouteType[] }> = ({ routes = [] }) => {
  const [openCreateWhModal, toggleOpenWhModal] = useState(false);
  const { t } = useTranslation();

  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />

      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe="30px">
          <WarehouseProvider>
            <ShynhAdminProvider>
              <WarehouseModal open={openCreateWhModal} onClose={() => toggleOpenWhModal(false)} />
              <WarehouseSelect />
              <Button w="100%" my={2} colorScheme="green" fontWeight="500" onClick={() => toggleOpenWhModal(true)}>
                {t('buttons.add')} {t('warehouse.name').toLowerCase()}
              </Button>
            </ShynhAdminProvider>
          </WarehouseProvider>
        </Box>
        <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }}>
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
};

export default SidebarContent;
