import { DeleteIcon } from '@chakra-ui/icons';
import {
  Card,
  CardBody,
  Flex,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { AuditField } from 'models/common';
import { TransactionI, TransactionStatus } from 'models/transaction';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { FC, useMemo } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MAX_INPUT_PRODUCTS } from 'utils/constants/transaction';

type TransactionItemWithId = FieldArrayWithId<Omit<TransactionI, AuditField>, 'productTransactions', 'formItemId'> & {
  index: number;
};

type CheckProductCardProps = {
  product: SearchProductI;
  transactionItems: TransactionItemWithId[];
  status?: TransactionStatus;
  onDeleteProduct: () => void;
};

const CheckProductCard: FC<CheckProductCardProps> = ({
  product,
  status,
  onDeleteProduct,
  transactionItems,
}) => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  const stock = useMemo(
    () =>
      product.inventories
        .reduce((arr: InventoryQuantityI[], inventory) => {
          const idx = arr.findIndex((item) => item.quantitationUnit === inventory.quantitationUnit);
          if (idx !== -1) arr[idx].quantity += inventory.quantity;
          else arr.push({ ...inventory });
          return arr;
        }, [])
        .map(({ quantitationUnit, quantity }) => `${quantity} ${quantitationUnit}`)
        .join(', '),
    [product.inventories]
  );

  return (
    <Card direction="column" variant="outline" key={product.id}>
      <CardBody>
        <Stack>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap={2} alignItems="center">
              <Image src={product.image} w="3rem" fallbackSrc="https://via.placeholder.com/150" />
              <Flex direction="column" justifyContent="space-between">
                <Text noOfLines={1}>{product.name}</Text>
                <Flex gap={1}>
                  <Tag colorScheme={product.inventories.length ? 'purple' : 'red'}>
                    <TagLabel>
                      {t('warehouse.fields.stock')}: {stock || 0}
                    </TagLabel>
                  </Tag>
                  {product.noExpiry && (
                    <Tooltip label="Sản phẩm này không cần Hạn sử dụng" hasArrow bgColor="orange.400" placement="top">
                      <Tag colorScheme="orange">Không HSD</Tag>
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            </Flex>
            {!status && (
              <IconButton
                tabIndex={-1}
                colorScheme="red"
                aria-label="delete-item"
                icon={<DeleteIcon />}
                onClick={onDeleteProduct}
              />
            )}
          </Flex>
          {transactionItems.map((item) => (
            <Flex key={item.formItemId} justifyContent="flex-start" alignItems="center" gap={2}>
              {!status && (
                <Flex alignItems="center" justifyContent="space-between" gap={1}>
                  <Text fontWeight={700} w="6rem">
                    SL chỉnh sửa
                  </Text>
                  <NumberInput w="10rem" min={1} max={MAX_INPUT_PRODUCTS} allowMouseWheel>
                    <NumberInputField
                      {...register(`productTransactions.${item.index}.actualQuantity`, {
                        min: 1,
                        max: MAX_INPUT_PRODUCTS,
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  {item.quantitationUnit && <Text minW="4rem">{item.quantitationUnit}</Text>}
                  {!product.noExpiry && (
                    <Tag colorScheme={product.noExpiry ? undefined : 'cyan'} w="8rem">
                      {!product.noExpiry && `HSD ${dayjs(item.expiry).format('DD/MM/YYYY')}`}
                    </Tag>
                  )}

                  <Tag colorScheme="purple" minW="8rem">
                    Hiện tại: {item.inventoryQuantity}
                  </Tag>
                </Flex>
              )}

              {status === TransactionStatus.DONE && (
                <Flex alignItems="center">
                  <Text w="10rem">Số lượng chỉnh sửa:</Text>
                  <Text fontWeight="700" w="6rem">
                    {item.actualQuantity} {item.quantitationUnit}
                  </Text>
                  <Tag colorScheme={product.noExpiry ? undefined : 'cyan'} w="8rem">
                    {!product.noExpiry && `HSD ${dayjs(item.expiry).format('DD/MM/YYYY')}`}
                  </Tag>
                </Flex>
              )}
            </Flex>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default CheckProductCard;
