import { Flex, Image } from '@chakra-ui/react';

import ShynhLogo from 'assets/img/logo.png';
import { HSeparator } from 'components/separator/Separator';
import { FC } from 'react';

const SidebarBrand: FC = () => (
  <Flex alignItems="center" flexDirection="column">
    <Image src={ShynhLogo} w="175px" my="32px" />
    <HSeparator mb="20px" />
  </Flex>
);

export default SidebarBrand;
