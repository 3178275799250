import { DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tooltip,
  useColorModeValue
} from '@chakra-ui/react';
import DatePicker from 'components/datepicker/ReactDatePicker';
import { WarehouseInventoryContext } from 'components/providers/WarehouseInventoryProvider';
import dayjs from 'dayjs';
import { ProductInventoryI, WarehouseProductI } from 'models/warehouse';
import { FC, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdSave } from 'react-icons/md';
import Select from 'react-select';
import { MAX_INPUT_PRODUCTS, NO_EXPIRE_DATE_ISO_STRING } from 'utils/constants/transaction';

type InventoryItem = Omit<ProductInventoryI, 'createdAt' | 'productId'>;

const InventoryDetail: FC<{ product?: WarehouseProductI }> = ({ product }) => {
  const { t } = useTranslation();
  const { update } = useContext(WarehouseInventoryContext);
  const [isEditingDirectly, toggleEditingDirectly] = useState(false);
  const [editingRows, setEditingRows] = useState<InventoryItem[]>([]);

  useEffect(() => {
    if (product?.productInventories) {
      setEditingRows([...(product.productInventories || [])]);
    }
  }, [product]);

  const addExpiryRowHandler = () =>
    setEditingRows([
      ...editingRows,
      {
        quantity: 0,
        expiry: product.noExpiry ? NO_EXPIRE_DATE_ISO_STRING : dayjs().startOf('D').toISOString(),
        quantitationUnit: product.baseUnit || '',
      },
    ]);

  const editExpiryRowHandler = (
    index: number,
    field: keyof Omit<ProductInventoryI, 'createdAt' | 'productId'>,
    value: number | string
  ) => {
    editingRows[index][field] = value as never;
    setEditingRows([...editingRows]);
  };

  const deleteExpiryRowHandler = (index: number) => {
    editingRows.splice(index, 1);
    setEditingRows([...editingRows]);
  };

  const startUpdateInventoryHandler = () => {
    if (!editingRows.length) {
      setEditingRows([
        {
          quantity: 0,
          expiry: product.noExpiry ? NO_EXPIRE_DATE_ISO_STRING : dayjs().startOf('D').toISOString(),
          quantitationUnit: product.baseUnit || '',
        },
      ]);
    }
    toggleEditingDirectly(true);
  };

  const saveChangeHandler = async () => {
    await update(
      product.id,
      editingRows.map(({ expiry, quantity, quantitationUnit }) => ({
        expiry,
        quantity,
        quantitationUnit,
      }))
    );
    toggleEditingDirectly(false);
  };

  const cancelChangeHandler = () => {
    setEditingRows([...(product?.productInventories || [])]);
    toggleEditingDirectly(false);
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const unitOptions = useMemo(
    () => [product.baseUnit, ...product.quantitationUnits.map((unit) => unit.unitName)],
    [product.baseUnit, product.quantitationUnits]
  );
  const hasMultipleRows = unitOptions.length > 1 || !product.noExpiry;

  const duplicatedMsg = useMemo(() => {
    if (unitOptions.length <= 1 && product.noExpiry) return undefined;
    const fields: string[] = [];
    unitOptions.length > 1 && fields.push('Đơn vị');
    !product.noExpiry && fields.push('Hạn sử dụng');

    const duplicatedLines: number[] = [];
    editingRows.reduce((existingItems: InventoryItem[], value, index) => {
      if (
        existingItems.some((item) => item.expiry === value.expiry && item.quantitationUnit === value.quantitationUnit)
      ) {
        duplicatedLines.push(index + 1);
      } else {
        existingItems.push(value);
      }
      return existingItems;
    }, []);

    return duplicatedLines.length ? `Trùng ${fields.join(', ')} ở dòng thứ ${duplicatedLines.join(', ')!}` : undefined;
  }, [editingRows, product.noExpiry, unitOptions]);

  return (
    <Stack spacing={4}>
      {product.noExpiry && (
        <Flex gap={4} alignItems="center">
          {t('warehouse.text.noExpiry')}
          <Tooltip hasArrow label={t('warehouse.text.noExpiryNote')} bg="gray.300" color="black">
            <InfoIcon color="gray.500" cursor="pointer" />
          </Tooltip>
        </Flex>
      )}
      {!editingRows.length && (
        <Alert status="warning">
          <AlertIcon />
          {t('warehouse.text.noProducts')}
        </Alert>
      )}
      {duplicatedMsg && (
        <Alert status="error">
          <AlertIcon />
          {duplicatedMsg}
        </Alert>
      )}
      <Grid templateColumns="repeat(10, 1fr)" gap={3}>
        <GridItem colSpan={3} fontWeight={700}>
          {t(`warehouse.fields.quantity`)}
        </GridItem>
        <GridItem colSpan={3} fontWeight={700}>
          {Boolean(product.baseUnit) && t(`products.fields.unit`)}
        </GridItem>
        <GridItem colSpan={4} fontWeight={700}>
          {!product.noExpiry && t(`warehouse.fields.expiry`)}
        </GridItem>
        {editingRows.map(({ expiry, quantity, quantitationUnit }, index) => (
          <Fragment key={`${expiry}|${quantitationUnit}-${index}`}>
            <GridItem colSpan={3}>
              <NumberInput
                isReadOnly={!isEditingDirectly}
                allowMouseWheel
                min={0}
                max={MAX_INPUT_PRODUCTS}
                value={quantity}
                onChange={(e) => editExpiryRowHandler(index, 'quantity', Number(e))}
              >
                <NumberInputField color={textColor} placeholder={t('warehouse.fields.quantity')} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </GridItem>
            <GridItem colSpan={3}>
              {unitOptions.length > 1 ? (
                <Select
                  styles={{
                    menu: (baseStyles) => ({
                      ...baseStyles,
                    }),
                  }}
                  value={{ value: quantitationUnit, label: quantitationUnit }}
                  onChange={(unit) => editExpiryRowHandler(index, 'quantitationUnit', unit.value)}
                  options={unitOptions.map((unit) => ({ value: unit, label: unit }))}
                />
              ) : (
                unitOptions[0] || ''
              )}
            </GridItem>
            <GridItem colSpan={3} zIndex={1}>
              {!product.noExpiry && (
                <DatePicker
                  readOnly={!isEditingDirectly}
                  minDate={dayjs().toDate()}
                  popperPlacement="right-start"
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(expiry)}
                  onChange={(e: Date) => editExpiryRowHandler(index, 'expiry', dayjs(e).startOf('D').toISOString())}
                />
              )}
            </GridItem>
            <GridItem>
              {isEditingDirectly && hasMultipleRows && editingRows.length > 1 && (
                <IconButton
                  tabIndex={-1}
                  aria-label="delete-prod"
                  bgColor="red"
                  _hover={{
                    bgColor: 'red.200',
                  }}
                  icon={<DeleteIcon color="white" />}
                  onClick={() => deleteExpiryRowHandler(index)}
                />
              )}
            </GridItem>
          </Fragment>
        ))}
      </Grid>

      {isEditingDirectly && hasMultipleRows && (
        <Button
          alignSelf="end"
          w="10rem"
          aria-label="add-prod"
          bgColor="teal"
          color="white"
          leftIcon={<FaPlus />}
          onClick={addExpiryRowHandler}
        >
          {t('buttons.add')}
        </Button>
      )}
      <Flex gap={3} justifyContent="end">
        {isEditingDirectly ? (
          <>
            <Button minW="7rem" colorScheme="red" rightIcon={<IoMdCloseCircle />} onClick={cancelChangeHandler}>
              {t('buttons.cancel')}
            </Button>
            <Button
              minW="7rem"
              colorScheme="blue"
              rightIcon={<MdSave />}
              onClick={saveChangeHandler}
              isDisabled={!!duplicatedMsg || !editingRows.length}
            >
              {t('buttons.save')}
            </Button>
          </>
        ) : (
          <Button rightIcon={<EditIcon />} minW="7rem" colorScheme="teal" onClick={startUpdateInventoryHandler}>
            {t('buttons.update')}
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export default InventoryDetail;
