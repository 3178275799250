import {
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { WarehouseTransactionContext } from 'components/providers/WarehouseTransactionProvider';
import { InventoryQuantityI, SearchProductI } from 'models/warehouse';
import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxOpen } from 'react-icons/fa';
import { searchWarehouseProducts } from 'utils/services/apis/inventory.api';

type SearchProductInputProps = {
  onSelectProduct: (product: SearchProductI, expiry?: string) => void;
};

const SearchProductInput: FC<SearchProductInputProps> = ({ onSelectProduct }) => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { warehouse } = useContext(WarehouseTransactionContext);

  const [openProducts, setOpenProducts] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [isTyping, setTyping] = useState(false);

  const { data: products = [], isFetching } = useQuery<SearchProductI[]>({
    queryKey: ['warehouse', 'search-products', search, warehouse?.id],
    queryFn: async ({ queryKey }) => await searchWarehouseProducts(queryKey[3] as string, queryKey[2] as string),
    enabled: openProducts,
  });

  useEffect(() => {
    const closePopup = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setOpenProducts(false);
      }
    };
    document.addEventListener('mousedown', closePopup);

    return () => {
      document.removeEventListener('mousedown', closePopup);
    };
  }, []);

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTyping(true);
    clearTimeout(timeoutRef?.current);
    timeoutRef.current = setTimeout(() => {
      setSearch(e.target.value);
      setTyping(false);
    }, 500);
  };

  const bgProductsCard = useColorModeValue('gray.100', 'gray.600');
  const bgProductCard = useColorModeValue('white', 'gray.700');
  const bgProductCardHover = useColorModeValue('gray.200', 'blue.900');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <InputGroup size="md" position="relative" ref={searchRef} zIndex={1}>
      <InputLeftAddon fontWeight="bold">{t('products.fields.name')}</InputLeftAddon>
      <Input
        placeholder="Tìm kiếm sản phẩm"
        color={textColor}
        onChange={searchHandler}
        onFocus={() => setOpenProducts(true)}
      />
      <Card
        bgColor={bgProductsCard}
        position="absolute"
        left={0}
        top="100%"
        w="100%"
        maxH={openProducts ? '30vh' : 0}
        transition="0.25s "
        overflowY={openProducts ? 'scroll' : 'hidden'}
      >
        <CardBody>
          {isFetching || isTyping ? (
            <Flex justifyContent="center">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Flex>
          ) : !products.length ? (
            <Heading size="md" textAlign="center" alignItems="center">
              <Icon as={FaBoxOpen} mr={2} />
              {t('products.text.noProductFound')}
            </Heading>
          ) : (
            <Grid templateColumns="repeat(4, 1fr)" gap="3">
              {products.map((product) => (
                <Tooltip label={`Sản phẩm ${product.name}`} key={product.id} placement="top">
                  <GridItem
                    p="0.5rem 1rem"
                    cursor="pointer"
                    borderRadius="0.5rem"
                    bgColor={bgProductCard}
                    _hover={{
                      bgColor: bgProductCardHover,
                    }}
                    boxShadow="2px 3px 5px 2px rgba(112, 144, 176, 0.2)"
                    onClick={() => onSelectProduct(product)}
                  >
                    <Flex gap={2}>
                      <Image
                        width={70}
                        height={70}
                        borderRadius="2px"
                        src={product.image}
                        fallbackSrc="https://via.placeholder.com/150"
                      />
                      <Flex direction="column" justifyContent="space-between" w="100%">
                        <Heading size="xs" textTransform="uppercase" noOfLines={2}>
                          {product.name}
                        </Heading>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Text pt="1" fontSize="sm">
                            {t('warehouse.fields.stock')}:{' '}
                            {product.inventories
                              .reduce((arr: InventoryQuantityI[], inventory) => {
                                const idx = arr.findIndex(
                                  (item) => item.quantitationUnit === inventory.quantitationUnit
                                );
                                if (idx !== -1) arr[idx].quantity += inventory.quantity;
                                else arr.push({ ...inventory });
                                return arr;
                              }, [])
                              .map(({ quantitationUnit, quantity }) => `${quantity} ${quantitationUnit}`)
                              .join(', ') || 0}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </GridItem>
                </Tooltip>
              ))}
            </Grid>
          )}
        </CardBody>
      </Card>
    </InputGroup>
  );
};

export default SearchProductInput;
