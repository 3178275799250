import dayjs from 'dayjs';
import { ProductI, ProductQuantitationUnitI } from 'models/product';
import { TransactionItemI } from 'models/transaction';
import { ProductInventoryI, SearchProductI } from 'models/warehouse';

export const validateImportPrimaryFormData = (
  transactionItems: TransactionItemI[],
  products: SearchProductI[]
): string[] => {
  return transactionItems
    .filter(
      (item, index, arr) =>
        arr.findLastIndex(
          ({ quantitationUnit, expiry, productId }) =>
            item.quantitationUnit === quantitationUnit && item.expiry === expiry && item.productId === productId
        ) !== index
    )
    .map(({ expiry, quantitationUnit, productId }) => {
      const { noExpiry, name } = products.find((p) => p.id === productId);
      const duplicatedExpiry = !noExpiry ? `HSD ${dayjs(expiry).format('DD/MM/YYYY')}` : undefined;
      const duplicatedUnit = quantitationUnit ? `Đơn vị ${quantitationUnit}` : undefined;

      return `Sản phẩm ${name} bị trùng ${[duplicatedUnit, duplicatedExpiry].filter((i) => Boolean(i)).join(', ')}`;
    });
};

type UnitQuantity = { [unit: string]: number };

// export const calculateUnitQuantities = (product: SearchProductI): UnitQuantity => {
//   // const units: { unitName: string; quantity: number }[] = [];
//   if (!product.quantitationUnits.length)
//     return { [product.baseUnit]: product.inventories.reduce((sum, v) => (sum += v.quantity), 0) };

//   const unitQuantitation: {
//     [uni: string]: {
//       quantity: number;
//       base: number;
//       higherUnit: string;
//     };
//   } = [product.baseUnit, ...product.quantitationUnits.map((u) => u.unitName)].reduce(
//     (obj, u, index, arr) => ({
//       ...obj,
//       [u]: {
//         quantity: product.inventories
//           .filter((inv) => inv.quantitationUnit === u)
//           .reduce((sum, inv) => (sum += inv.quantity), 0),
//         base: product.quantitationUnits.find((q) => u === q.unitName)?.totalInHigherUnit,
//         higherUnit: arr[index - 1],
//       },
//     }),
//     {}
//   );

//   // vừa mới đổi quantitation thành object hôm qua, cần migrate=> change frontend product...
//   // return unitQuantity;
// };

// have totalMinUnit
// eg: [[0]: thùng 48,
//      [1]: hộp   12
//      [2]: bộ     2
//      [3]: chai   1
