import { MdOutlineChecklist } from 'react-icons/md';

import {
  Checkbox,
  Flex,
  IconButton,
  Image,
  Skeleton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { WarehouseInventoryContext } from 'components/providers/WarehouseInventoryProvider';
import dayjs from 'dayjs';
import { ProductInventoryI, WarehouseProductI } from 'models/warehouse';
import { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxOpen } from 'react-icons/fa6';
import { formatCurrency } from 'utils/helper.ts/common';
import InventoriesModal from './InventoryDetail/InventoriesModal';
import ProductFilter from 'views/admin/products/components/ProductFilter';
import Pagination from 'components/pagination';

const InventoriesTable: FC<{ warehouseId: string }> = ({ warehouseId }) => {
  const { t } = useTranslation();
  const { data } = useContext(WarehouseInventoryContext);
  const [selectedProduct, setSelectedProduct] = useState<WarehouseProductI>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = useMemo<ColumnDef<WarehouseProductI>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            color="gray.700"
            isChecked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            isIndeterminate={row.getIsSomeSelected()}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              row.getToggleSelectedHandler()(e);
            }}
          />
        ),
      },
      {
        id: 'images',
        accessorKey: 'images',
        header: '',
        cell: ({ getValue }) => <Image src={getValue<string[]>()[0]} width={70} height={70} />,
      },
      {
        id: 'code',
        accessorKey: 'code',
        header: t('products.fields.code'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: t('products.fields.name'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        accessorFn: (row) => `${formatCurrency(row.originalPrice)} ${row.currency}`,
        id: 'originalPrice',
        header: t('products.fields.originalPrice'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        accessorFn: (row) => `${formatCurrency(row.sellingPrice)} ${row.currency}`,
        id: 'sellingPrice',
        header: t('products.fields.sellingPrice'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        id: 'supplier',
        accessorFn: ({ supplier }) => supplier?.name ?? t('common.na'),
        header: t('products.fields.supplier'),
        cell: ({ getValue }) => getValue<String>(),
      },
      {
        id: 'productGroup',
        accessorFn: ({ productGroup }) => productGroup?.name ?? t('common.na'),
        header: t('products.fields.group'),
        cell: ({ getValue }) => getValue<string>(),
      },
      {
        id: 'count',
        accessorKey: 'productInventories',
        header: t('warehouse.fields.quantity'),
        cell: ({ getValue }) => {
          const inventories = getValue<ProductInventoryI[]>();
          const count = inventories.reduce((previous, value) => previous + value.quantity, 0);
          return count;
        },
      },
      {
        id: 'comingExpiry',
        accessorKey: 'productInventories',
        header: t('warehouse.fields.comingExpiry'),
        cell: ({ getValue }) => {
          const inventories = getValue<ProductInventoryI[]>();
          let text = '';
          if (inventories.length) {
            const minExpiry = Math.min.apply(
              Math,
              inventories.map((x) => dayjs(x.expiry).valueOf())
            );
            text = dayjs(minExpiry).format('DD/MM/YYYY');
            text = text === '01/01/1970' ? t('warehouse.text.noExpiryShort') : text;
          }

          return text;
        },
      },
    ],
    [t]
  );

  const inventoriesTable = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    getRowId: (e) => e.id,
    enableRowSelection: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <>
      {selectedProduct && <InventoriesModal product={selectedProduct} onClose={() => setSelectedProduct(undefined)} />}
      <Card flexDirection="column" w="100%">
        <Flex mb="8px" justifyContent="space-between" align="center">
          <Text color={textColor} fontSize="22px" mb="4px" fontWeight={700}>
            {t('products.title')}
          </Text>
          <ProductFilter type="inventory" />
        </Flex>
        <TableContainer>
          <Table size="sm" mb="24px" mt="30px">
            {!data?.length && (
              <TableCaption>
                <Flex justify="center" alignItems="center" gap={2}>
                  <FaBoxOpen /> Không có sản phẩm nào
                </Flex>
              </TableCaption>
            )}
            <Thead bgColor="green" color="white">
              {inventoriesTable.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      color="inherit"
                      py={5}
                      key={header.id}
                      colSpan={header.colSpan}
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {inventoriesTable.getRowModel().rows.map((row, index) => (
                <Tr
                  key={row.id}
                  fontSize="14px"
                  bgColor={index % 2 ? 'white' : 'green.50'}
                  _hover={{
                    bgColor: 'green.200',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedProduct(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan={11}>
                  <Pagination
                    currentPage={inventoriesTable.getState().pagination.pageIndex + 1}
                    totalPages={inventoriesTable.getPageCount()}
                    totalRows={data.length}
                    onPageIndexChange={inventoriesTable.setPageIndex}
                    pageSize={inventoriesTable.getState().pagination.pageSize}
                    setPageSize={inventoriesTable.setPageSize}
                    canNextPage={inventoriesTable.getCanNextPage()}
                    canPreviousPage={inventoriesTable.getCanPreviousPage()}
                  />
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default InventoriesTable;
